import gql from 'graphql-tag';

export const CHANGE_EMAIL = gql`
    mutation ChangeEmailRequest($input: ChangeEmailRequestInput!) {
        changeEmailRequest(input: $input) {
            done
        }
    }
`;

export const CHANGE_EMAIL_VALIDATION = gql`
    mutation ChangeEmail($input: ChangeEmailInput!) {
        changeEmail(input: $input) {
            user {
                email
            }
        }
    }
`;