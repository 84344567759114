import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: '1300px',
        minWidth: '968px',
        width: '100%',
        margin: '0 auto',
        paddingBottom: `${theme.spacing(12.5)}px`
    },
    title: {
        width: '100%',
        textAlign: 'center',
        marginTop: `${theme.spacing(8)}px`,
        position: 'relative'
    }
}));

const ChangeEmail = props => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} spacing={8} justify="space-between">
            <Grid item xs={12}>
                <Typography className={classes.title} variant="h2">
                    E-mail aanvraag verstuurd
                </Typography>
                <Typography className={classes.title}>
                    Je ontvangt een bevestigingsmail op je nieuwe e-mailadres. Volg de instructies om je aanvraag te voltooien.
                </Typography>
            </Grid>
        </Grid>
    )
}

export default ChangeEmail