import gql from 'graphql-tag';

export const GET_REGIO_LOGO = gql`
    query user($id: Long!) {
        user(id: $id) {
        corporation {
            id
            name
            region {
                id
                description
                logo {
                    id
                    url
                }
            }
        }
      }
    }
`;
