import React from 'react';
import CustomIcon from './CustomIcon'

const PdfFile = props => (
    <CustomIcon {...props} svgAtts={{ viewBox: '0 0 550.801 550.801'}}>
        <path d="M267.342 414.698c-6.613 0-10.884.585-13.413 1.165v85.72c2.534.586 6.616.586 10.304.586 26.818.189 44.315-14.576 44.315-45.874.19-27.216-15.745-41.597-41.206-41.597zM152.837 414.313c-6.022 0-10.104.58-12.248 1.16v38.686c2.531.58 5.643.78 9.903.78 15.757 0 25.471-7.973 25.471-21.384.001-12.049-8.362-19.242-23.126-19.242z"/>
        <path d="M475.095 131.992c-.032-2.526-.833-5.021-2.568-6.993L366.324 3.694c-.021-.034-.062-.045-.084-.076-.633-.707-1.36-1.29-2.141-1.804-.232-.15-.475-.285-.718-.422-.675-.366-1.382-.67-2.13-.892-.19-.058-.38-.14-.58-.192C359.87.114 359.037 0 358.203 0H97.2C85.292 0 75.6 9.693 75.6 21.601v507.6c0 11.913 9.692 21.601 21.6 21.601h356.4c11.908 0 21.601-9.688 21.601-21.601V133.202c-.001-.406-.064-.804-.106-1.21zM193.261 463.873c-10.104 9.523-25.072 13.806-42.569 13.806-3.882 0-7.391-.2-10.102-.58v46.839h-29.35V394.675c9.131-1.55 21.967-2.721 40.047-2.721 18.267 0 31.292 3.501 40.036 10.494 8.363 6.612 13.985 17.497 13.985 30.322 0 12.835-4.266 23.72-12.047 31.103zm124.991 44.519c-13.785 11.464-34.778 16.906-60.428 16.906-15.359 0-26.238-.97-33.637-1.94V394.675c10.887-1.74 25.083-2.721 40.046-2.721 24.867 0 41.004 4.472 53.645 13.995 13.61 10.109 22.164 26.241 22.164 49.37-.011 25.081-9.145 42.378-21.79 53.073zm121.32-91.167h-50.351v29.932h47.039v24.11h-47.039v52.671H359.49V392.935h80.082v24.29zM97.2 366.752V21.601h250.203v110.515c0 5.961 4.831 10.8 10.8 10.8H453.6l.011 223.836H97.2z"/>
        <path d="M386.205 232.135c-.633-.059-15.852-1.448-39.213-1.448-7.319 0-14.691.143-21.969.417-46.133-34.62-83.919-69.267-104.148-88.684.369-2.138.623-3.828.741-5.126 2.668-28.165-.298-47.179-8.786-56.515-5.558-6.101-13.721-8.131-22.233-5.806-5.286 1.385-15.071 6.513-18.204 16.952-3.459 11.536 2.101 25.537 16.708 41.773.232.246 5.189 5.44 14.196 14.241-5.854 27.913-21.178 88.148-28.613 117.073-17.463 9.331-32.013 20.571-43.277 33.465l-.738.844-.477 1.013c-1.16 2.437-6.705 15.087-2.542 25.249 1.901 4.62 5.463 7.995 10.302 9.767l1.297.349s1.17.253 3.227.253c9.01 0 31.25-4.735 43.179-48.695l2.89-11.138c41.639-20.239 93.688-26.768 131.415-28.587 19.406 14.391 38.717 27.611 57.428 39.318l.611.354c.907.464 9.112 4.515 18.721 4.524 13.732 0 23.762-8.427 27.496-23.113l.189-1.004c1.044-8.393-1.065-15.958-6.096-21.872-10.598-12.458-30.331-13.544-32.104-13.604zm-243.393 87.609c-.084-.1-.124-.194-.166-.3-.896-2.157.179-7.389 1.761-11.222 6.792-7.594 14.945-14.565 24.353-20.841-9.162 29.658-22.486 32.222-25.948 32.363zm58.172-197.049c-14.07-15.662-13.859-23.427-13.102-26.041 1.242-4.369 6.848-6.02 6.896-6.035 2.824-.768 4.538-.617 6.064 1.058 3.451 3.791 6.415 15.232 5.244 36.218-3.322-3.338-5.102-5.2-5.102-5.2zm-7.27 133.373l.243-.928-.032.011c7.045-27.593 17.205-67.996 23.047-93.949l.211.201.021-.124c18.9 17.798 47.88 43.831 82.579 70.907l-.39.016.574.433c-32.688 2.761-71.73 9.205-106.253 23.433zm214.672 9.052c-2.489 9.146-7.277 10.396-11.665 10.396-5.094 0-9.998-2.12-11.116-2.632-12.741-7.986-25.776-16.688-38.929-25.998h.316c22.549 0 37.568 1.369 38.158 1.411 3.766.14 15.684 1.9 20.82 7.938 2.014 2.367 2.785 5.196 2.416 8.885z"/>
    </CustomIcon>
);

export default PdfFile;