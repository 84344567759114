import gql from 'graphql-tag';

const GET_VACANCY = gql`
    query vacancy($id: Long!) {
        vacancy(id: $id) {
            id
            title
            draft
            description
            originURL
            videoURL
            crawled
            apiKeys {
                id
                description
            }
            imageOrVideo {
                id
                name
                contentType
                url
            }
            attachments {
                id
                name
                contentType
                url
            }
            city {
                name
                id
            }
            type {
                id
                description
            }
            corporation {
                id
                name
                domain
            }
            responses {
                id {
                    userId
                    vacancyId
                }
                motivation
            }
            workingHoursAWeek {
                value
                id
            }
            salaryScale {
                id
                description
            }
            createdOn
            contactPerson {
                id
            }
            educationGrade {
                id
                description
            }
            duration {
                id
                description
            }
            type {
                id
                description
                explanation
            }
            publicationPeriod {
                id
                endDate
                startDate
                visibility {
                    id
                    description
                }
            }
            visibility {
                id
                description
            }
            activePublicationPeriod {
                endDate
                startDate
                id
                visibility {
                    id
                    description
                }
            }
            createdBy {
                id
                profile {
                    fullName
                }
            }
            city {
                name
                province
            }
            expertiseList {
                id
                description
            }
        }
    }
`;

const GET_VACANCIES = gql`
    query vacancies($pagination: PaginationInput!, $filter: VacancyFilterInput) {
        vacancies(pagination: $pagination, filter: $filter) {
            vacancies {
                id
                title
                crawled
                originURL
                corporation {
                    name
                    logo {
                        thumbnailUrl
                        url
                    }
                }
                contactPerson {
                    id
                }
                description
                city {
                    name
                }
                type {
                    id
                    description
                }
                workingHoursAWeek {
                    value
                }
            }
            count
        }
    }
`;

const VIEW_VACANCY = gql`
    mutation viewVacancy($input: ViewVacancyInput!) {
        viewVacancy(input: $input)
    }
`;

const SET_VACANCY = gql`
    mutation createVacancy($input: CreateVacancyInput!) {
        createVacancy(input: $input) {
            vacancy {
                id
                title
            }
        }
    }
`;

const EDIT_VACANCY = gql`
    mutation updateVacancy($input: UpdateVacancyInput!) {
        updateVacancy(input: $input) {
            vacancy {
                id
                title
            }
        }
    }
`;

const PUBLISH_VACANCY = gql`
    mutation publishVacancy($input: PublishVacancyInput!) {
        publishVacancy(input: $input) {
            vacancy {
                id
                title
            }
        }
    }
`;

const DELETE_VACANCY = gql`
    mutation deleteVacancy($input: DeleteVacancyInput!) {
        deleteVacancy(input: $input)
    }
`;

const DUPLICATE_VACANCY = gql`
    mutation copy($input: DuplicateVacancyInput!) {
        duplicateVacancy(input: $input) {
            vacancy {
                id
                title
            }
        }
    }
`;

const SET_LIKE_VACANCY = gql`
    mutation($input: LikeVacancyInput!) {
        likeVacancy(input: $input) {
            liked
            vacancyId
        }
    }
`;

const GET_MY_VACANCIES = gql`
    query myVacancies($pagination: PaginationInput, $filter: MyVacancyFilterInput) {
        myVacancies(pagination: $pagination, filter: $filter) {
            vacancies {
                id
                createdOn
                title
                likedBy {
                    id
                }
                responses {
                    id {
                        userId
                        vacancyId
                    }
                }

                publicationPeriod {
                    endDate
                    startDate
                    visibility {
                        description
                    }
                }
                visibility {
                    description
                    id
                }
            }
            count
        }
    }
`;

const GET_EXPERTISES = gql`
    query expertises {
        expertises(pagination: { page: 0, pageSize: 100 }) {
            count
            expertises {
                description
                id
            }
        }
    }
`;

const GET_CITIES = gql`
    query cities($filter: CityFilterInput!) {
        cities(pagination: { page: 0, pageSize: 50 }, filter: $filter) {
            count
            cities {
                id
                name
            }
        }
    }
`;

const GET_WORKING_HOURS = gql`
    query {
        workingHoursList {
            workingHoursList {
                id
                value
            }
            count
        }
    }
`;

const GET_PROVINCES = gql`
    query {
        provinces
    }
`;

const GET_DURATIONS = gql`
    query {
        durations(pagination: { page: 0, pageSize: 50 }) {
            durations {
                id
                description
            }
        }
    }
`;

const UPLOAD_FILE = gql`
    mutation uploadFile($file: Upload!) {
        uploadFile(file: $file) {
            asset {
                id
                contentType
                url
            }
        }
    }
`;

const GET_VACANCY_TYPES = gql`
    query vacancyTypes {
        paginatedVacancyTypes(pagination: { page: 0, pageSize: 50 }) {
            types {
                description
                id
                explanation
            }
        }
    }
`;

const GET_EDUCATION_GRADES = gql`
    query educationGrades {
        educationGrades(pagination: { page: 0, pageSize: 50 }) {
            educationGrades {
                id
                description
            }
        }
    }
`;

const GET_VISIBILITIES = gql`
    query visibilities {
        vacancyVisibilities(pagination: { page: 0, pageSize: 50 }) {
            vacancyVisibilities {
                description
                id
            }
        }
    }
`;

const GET_CORPORATIONS = gql`
    query getCorporations {
        corporations(pagination: { page: 0, pageSize: 100 }) {
            corporations {
                id
                name
                region {
                    id
                }
            }
        }
    }
`;

const GET_CONTACT_PERSONS = gql`
    query getContactPersons {
        contactPersons {
            id
            email
            profile {
                fullName
            }
        }
    }
`;

const SEARCH_TRIGGERS = gql`
    query searchTriggers {
        searchTriggers {
            city {
                id
                name
            }
            distance
            search
            active
            id
            lastRun
            cities
            expertiseList
            durations
            provinces
            publicationDates
            regions
            salaryScales
            type
            workingHours
        }
    }
`;

const DELETE_ALERT = gql`
    mutation searchAlert($id: Long!) {
        removeSearchAlert(alertId: $id) {
            id
        }
    }
`;

const UPDATE_ALERT = gql`
    mutation updateAlert($input: AlertInput!) {
        updateSearchAlert(input: $input) {
            id
        }
    }
`;

const RESPOND_TO_VACANCY = gql`
    mutation response($input: RespondToVacancyInput!) {
        respondToVacancy(input: $input) {
            vacancyId
        }
    }
`;

const GET_LIKED = gql`
    query user($id: Long!) {
        user(id: $id) {
            id
            respondedVacancies {
                id {
                    userId
                    vacancyId
                }
                motivation
                vacancy {
                    title
                }
            }
            likedVacancies {
                id
                title
                workingHoursAWeek {
                    id
                    value
                }
                city {
                    id
                    name
                }
                visibility {
                    id
                    description
                }
            }
        }
    }
`;

const GET_SALARY_SCALES = gql`
    query salaryScales {
        salaryScales {
            types {
                id
                description
            }
        }
    }
`;

const GET_PUB_DATES = gql`
    query publicationDate {
        publicationDates {
            publicationDates {
                description
                id
            }
        }
    }
`;

const GET_REGIONS = gql`
    query {
        regions {
            regions {
                description
                id
            }
        }
    }
`;

const GET_USER = gql`
    query {
        currentUser {
            id
            careerProfile {
                cv {
                    id
                    name
                    url
                }
                documents {
                    id
                    name
                    url
                }
            }
        }
    }
`;

export {
    DELETE_ALERT,
    DELETE_VACANCY,
    DUPLICATE_VACANCY,
    EDIT_VACANCY,
    PUBLISH_VACANCY,
    SEARCH_TRIGGERS,
    GET_CITIES,
    GET_CONTACT_PERSONS,
    GET_CORPORATIONS,
    GET_DURATIONS,
    GET_EDUCATION_GRADES,
    GET_EXPERTISES,
    GET_LIKED,
    GET_MY_VACANCIES,
    GET_REGIONS,
    GET_PROVINCES,
    GET_PUB_DATES,
    GET_SALARY_SCALES,
    GET_VACANCIES,
    GET_VACANCY_TYPES,
    GET_VACANCY,
    GET_VISIBILITIES,
    GET_WORKING_HOURS,
    RESPOND_TO_VACANCY,
    SET_LIKE_VACANCY,
    SET_VACANCY,
    UPDATE_ALERT,
    UPLOAD_FILE,
    GET_USER,
    VIEW_VACANCY
};
