import { createMuiTheme } from '@material-ui/core';
import { galano, galanoBold, galanoLight, galanoMedium } from './font';

let theme = createMuiTheme({});

theme = createMuiTheme({
    typography: {
        fontFamily: 'Galanogrotesque',
        fontSize: 14,
        htmlFontSize: 16,
        fontWeightRegular: 'normal',
        color: '#424242',
        h1: {
            fontSize: '70px',
            lineHeight: '1.142857142857143',
            color: '#2C276A',
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
                fontSize: '42px'
            }
        },
        h2: {
            fontSize: '48px',
            lineHeight: '1.208333333333333',
            color: '#2C276A',
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
                fontSize: '38px'
            }
        },
        h3: {
            fontSize: '38px',
            lineHeight: '1.263157894736842',
            color: '#2C276A',
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
                fontSize: '28px'
            }
        },
        h4: {
            fontSize: '32px',
            lineHeight: '1.1875',
            color: '#2C276A',
            fontWeight: 700,
            [theme.breakpoints.down('sm')]: {
                fontSize: '22px'
            }
        },
        h5: {
            fontSize: '26px',
            lineHeight: '32px',
            color: '#2C276A',
            fontWeight: 600
        },
        h6: {
            fontSize: '20px',
            lineHeight: '1.4',
            color: '#2C276A',
            [theme.breakpoints.down('sm')]: {
                fontSize: '18px'
            }
        },
        subtitle1: {
            fontSize: '18px',
            lineHeight: '32px',
            color: '#2C276A',
            fontWeight: 500
        },
        body1: {
            fontSize: '18px',
            lineHeight: '32px',
            fontWeight: 'normal',
            [theme.breakpoints.down('sm')]: {
                fontSize: '16px',
                lineHeight: '30px'
            }
        },
        body2: {
            fontSize: '16px',
            lineHeight: '30px',
            fontWeight: 'normal'
        },
        button: {
            fontSize: '18px',
            textTransform: 'none',
            boxShadow: 'none'
        }
    },
    palette: {
        common: { black: '#000', white: '#fff' },
        background: {
            paper: '#fff',
            default: '#fff',
            primary: '#F8F5F9',
            primaryContrast: '#F0EAF2',
            secondary: '#D4ECF7',
            secondaryContrast: '#8bcce9',
            blue: '#d2ee7e',
            blueContrast: '#b7e431',
            pink: '#FADCDC',
            pinkContrast: '#ef9090',
            yellow: '#FFE88B',
            yellowContrast: '#ffd734',
            dark: '#acd5e8',
            opacity: 'rgba(255, 255, 255, 0.75)'
        },
        primary: {
            light: '#D0BADA',
            main: '#6E3286',
            lightBackground: '#7a3b93',
            contrastText: '#fff'
        },
        secondary: {
            light: '#D2EE7E',
            main: '#B3D450',
            contrastText: '#2C276A'
        },
        error: {
            light: 'rgba(232, 138, 131, 1)',
            main: 'rgba(217, 40, 28, 1)',
            dark: 'rgba(155, 26, 18, 1)',
            contrastText: '#fff'
        },
        text: {
            primary: '#424242',
            secondary: '#2C276A',
            secondaryLight: 'rgba(44, 39, 106, 0.5)',
            hover: '#6FC6F0',
            normal: '#555555',
            disabled: 'rgba(49, 45, 119, 0.6)',
            hint: 'rgba(0, 0, 0, 0.38)',
            icon: 'rgba(66,66,66,0.5)'
        },
        divider: 'rgba(44,39,106,0.15)',
        borderColor: {
            main: 'rgba(16, 16, 24, 0.2)',
            primary: '#e7dcec',
            secondary: '#D0BADA'
        },
        shape: {
            borderRadius: 35
        }
    },
    overrides: {
        MuiCssBaseline: {
            '@global': {
                '@font-face': [galano, galanoLight, galanoBold, galanoMedium]
            }
        },
        MuiPopover: {
            paper: {
                minWidth: '240px'
            }
        },
        MuiInput: {
            underline: {
                minWidth: '100px',
                minHeight: '20px'
            }
        },
        MUIRichTextEditor: {
            container: {
                position: 'relative'
            },
            placeHolder: {
                height: '100%',
                position: 'absolute'
            }
        },
        MuiSvgIcon: {
            colorSecondary: {
                color: '#fff'
            }
        },
        MuiExpanded: {
            margin: 0
        },
        MuiInputRoot: {
            marginTop: '8px'
        },
        label: {
            '+ .MuiInput-formControl': {
                marginTop: '8px'
            }
        }
    },
    props: {
        MuiButtonBase: {
            // The properties to apply
            disableRipple: true
        }
    }
});

export default theme;