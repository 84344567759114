import React from 'react';
import CustomIcon from './CustomIcon'

const Linkedin = props => {
  return (
    <CustomIcon {...props} width="455.313" height="500">
        <path
          fill="currentColor"
          className="prefix__bacls-1"
          d="M54.546 133.163h-.593c-32.9 0-53.945-22.655-53.945-50.972 0-28.909 21.936-50.967 55.135-50.967 33.492 0 54.244 22.058 54.538 50.967.298 28.317-21.046 50.972-55.135 50.972zm48.909 335.612H5.641V173.4h97.814v295.375zm351.845 0h-97.519V310.804c0-39.642-14.229-66.765-49.5-66.765-26.973 0-42.982 18.18-50.094 35.765-2.373 6.56-3.259 15.2-3.259 24.145v164.826h-97.523c1.185-267.656 0-295.375 0-295.375h97.521v42.921h-.593c12.745-20.271 35.866-49.776 88.627-49.776 64.322 0 112.34 42.325 112.34 132.931v169.3z"
        />
    </CustomIcon>
  );
};

export default Linkedin;
