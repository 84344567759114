import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LoadingScreen from 'components/LoadingScreen';
import App from 'components/App';
import { setUserData } from 'store/actions';

const Bootstrap = () => {
    const [appState, setAppState] = useState('loading');
    const dispatch = useDispatch();
    const token = useSelector(state => state.auth.token);
    const userId = useSelector(state => state.auth.userId);
    const user = useSelector(state => state.auth.user);

    useEffect(() => {
        if (!token || token === 'deleted') {
            setAppState('done');
        } else if (token && userId) {
            dispatch(setUserData(userId));
        }
    }, [dispatch, token, userId]);

    useEffect(() => {
        if (user) {
            setAppState('done');
        }
    }, [user]);

    return (
        <React.Fragment>
            {appState === 'loading' && <LoadingScreen />}
            {appState === 'done' && <App />}
        </React.Fragment>
    );
};

export default Bootstrap;
