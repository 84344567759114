import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useMutation, useQuery} from '@apollo/react-hooks';
import merge from 'deepmerge';
import {useSnackbar} from 'notistack';
import {makeStyles} from '@material-ui/styles';
import {Add} from '@material-ui/icons';
import {Box, Grid, Paper, Typography} from '@material-ui/core';
import ClearTwoToneIcon from '@material-ui/icons/ClearTwoTone';
import {AutoCompleteInput, Breadcrumbs, Button, Checkbox, DatePicker, FileUpload, InputField, LoaderModal, RichInputField, SelectField, SubMenu} from 'shared';
import {useForm} from 'hooks';
import {EXPERTISES} from 'api';
import {
    GET_CITIES,
    GET_CONTACT_PERSONS,
    GET_DURATIONS,
    GET_EDUCATION_GRADES,
    GET_VACANCY,
    GET_VACANCY_TYPES,
    GET_VISIBILITIES,
    GET_WORKING_HOURS,
    SET_VACANCY,
    UPLOAD_FILE
} from '../queries';
import {convertToSlug, isUndefinedOrNullOrEmptyString} from 'helpers';

const useStyles = makeStyles((theme) => ({
    root: {
        background: theme.palette.background.secondary,
        paddingBottom: `${theme.spacing(12)}px`
    },
    grid: {
        maxWidth: '1165px',
        margin: '0 auto'
    },
    paper: {
        width: '100%',
        padding: `${theme.spacing(7)}px ${theme.spacing(8)}px ${theme.spacing(8)}px`,
        borderRadius: '25px',

        '& .MuiGrid-grid-xs-6': {
            maxWidth: 'calc(50% - 50px)',
            flexBasis: 'calc(50% - 50px)',
            '& .MuiGrid-grid-xs-6': {
                maxWidth: 'calc(50% - 15px)',
                flexBasis: 'calc(50% - 15px)'
            }
        }
    },
    title: {
        textAlign: 'center',
        paddingTop: `${theme.spacing(8)}px`,
        paddingBottom: `${theme.spacing(2)}px`
    },
    heading: {
        ...theme.typography.h6,
        fontWeight: 600,
        paddingBottom: `${theme.spacing(2)}px`,
        borderBottom: '1px solid ' + theme.palette.borderColor.main,
        marginBottom: `${theme.spacing(1)}px`
    },
    label: {
        ...theme.typography.h6,
        fontWeight: 500,
        fontSize: '1.15em',
        padding: `${theme.spacing(2.25)}px 0 ${theme.spacing(1)}px`
    },
    input: {
        width: '100%',
        marginLeft: 0,
        '& .MuiInputLabel-shrink': {
            transform: 'none',
            top: '-20px',
            lineHeight: 1.5,
            fontWeight: '500',
            fontSize: '1.15em'
        }
    },
    customGrid: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap'
    },
    gridItem: {
        flex: '0 0 33%'
    },
    addBar: {
        ...theme.typography.body1,
        color: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        padding: `${theme.spacing(1.5)}px 0`,
        cursor: 'pointer',
        userSelect: 'none',
        '&:hover': {
            '& .MuiSvgIcon-root': {
                transform: 'rotate(90deg)'
            }
        },
        '& .MuiSvgIcon-root': {
            marginRight: theme.spacing(1.5),
            transition: 'transform 300ms ease'
        }
    },
    lightbox: {
        marginLeft: 0,
        padding: `${theme.spacing(1.5)}px 0`
    }
}));

const EditVacancy = (props) => {
    const {t} = useTranslation();
    const classes = useStyles();
    const [form, submitForm] = useForm();
    const history = useHistory();
    const {id} = useParams();
    const {enqueueSnackbar} = useSnackbar();

    const {
        loading: loadingVacancy,
        error: vacancyError,
        data: vacancyData = {}
    } = useQuery(GET_VACANCY, {
        variables: {
            id: id
        }
    });
    const [setVacancy, {loading: mutationLoading, error: mutationError}] = useMutation(SET_VACANCY);

    const BreadcrumbPath = useMemo(
        () => [
            {
                title: 'Kansen',
                link: '/kansen'
            },
            {
                title: 'Kans berwerken',
                link: `/hr-kansen/bewerken/${id}`
            }
        ],
        [id]
    );

    const {data: workingData = {}} = useQuery(GET_WORKING_HOURS);
    const {data: expertisesData = {}} = useQuery(EXPERTISES);
    const {data: typesData = {}} = useQuery(GET_VACANCY_TYPES);
    const {data: contactData = {}} = useQuery(GET_CONTACT_PERSONS);
    const {data: educationData = {}} = useQuery(GET_EDUCATION_GRADES);
    const {data: durationData = {}} = useQuery(GET_DURATIONS);
    const {data: visibilityData = {}} = useQuery(GET_VISIBILITIES);
    const {data: cityData = {}} = useQuery(GET_CITIES);

    const {workingHoursList = []} = workingData;
    const {expertises = []} = expertisesData;
    const {paginatedVacancyTypes = []} = typesData;
    const {contactPersons = []} = contactData;
    const {educationGrades = []} = educationData;
    const {durations = []} = durationData;
    const {vacancyVisibilities = []} = visibilityData;
    const {cities = []} = cityData;

    const [uploadFile] = useMutation(UPLOAD_FILE);

    const [draft, setDraft] = useState({
        title: '',
        type: '',
        educationGrade: '',
        salaryScale: '',
        city: 0,
        contactPerson: '',
        duration: '',
        workingHoursAWeek: '',
        description: '',
        expertiseList: [],
        visibleInApi: false,
        publicationPeriod: []
    });

    const removeEmpty = useCallback((obj) => {
        if (!obj) return;
        Object.keys(obj).forEach(function (key) {
            if (obj[key] && typeof obj[key] === 'object') removeEmpty(obj[key]);
            else if (obj[key] == null) delete obj[key];
        });
    }, []);

    useEffect(() => {
        let data = vacancyData.vacancy;
        if (data) {
            removeEmpty(data);

            const newExpList = data.expertiseList.map((item) => item.id);
            data.expertiseList = newExpList;

            console.log('VacancyData: ', vacancyData);
            const visibleInApi = data.apiKeys && data.apiKeys.length !== 0;
            data.visibleInApi = visibleInApi;

            setDraft((draft) => merge.all([draft, data]));
        }
    }, [vacancyData, removeEmpty]);

    useEffect(() => {
        if (vacancyError) {
            enqueueSnackbar({
                variant: 'warning',
                message: t('Er is iets mis gegaan met het ophalen van de data')
            });
        }
    }, [enqueueSnackbar, t, vacancyError]);

    const mapToSelect = (array, label, value) => {
        if (!array) return;

        let newArray = array.map((item) => {
            return {
                label: item[label],
                value: item[value]
            };
        });

        return newArray;
    };

    const handleSave = () => {
        submitForm().then((resp) => {
            setVacancy({
                variables: {
                    input: {
                        city: resp.city,
                        description: '',
                        ...resp,
                        publicationPeriod: resp.publicationPeriod,
                        expertiseList: resp.expertiseList,
                        workingHoursAWeek: isUndefinedOrNullOrEmptyString(resp.workingHoursAWeek)
                            ? null
                            : resp.workingHoursAWeek
                    }
                }
            });
        });
    };

    const handleFile = (file) => {
        if (file) {
            uploadFile({
                variables: {
                    file: file
                },
                context: {
                    hasUpload: true
                }
            })
                .then((response) => {
                    // let { data: { uploadFile } } = response;
                })
                .catch((error) => {
                    console.warn('uploadDocument error: ', error);
                });
        }
    };

    const clearFile = (file) => {
    };

    const addPublication = () => {
        let newPeriods = draft.publicationPeriod;
        newPeriods.push({
            endDate: '',
            startDate: '',
            visibility: 'VISIBLY_FOR_ALL_CORPORATIONS'
        });
        setDraft((draft) => ({
            ...draft,
            publicationPeriod: newPeriods
        }));
    };

    const handlePeriodChange = (name, id, value) => {
        const periods = draft.publicationPeriod;
        let periode = periods[id];
        periode[name] = value;

        setDraft((draft) => ({
            ...draft,
            publicationPeriod: periods
        }));
    };

    const handleCheckbox = (id) => {
        let exList = draft.expertiseList;
        if (exList.includes(id)) {
            exList.splice(exList.indexOf(id), 1);
        } else {
            exList.push(id);
        }
        setDraft((draft) => ({
            ...draft,
            expertiseList: exList
        }));
    };

    return (
        <React.Fragment>
            <SubMenu
                renderLeft={() => (
                    <Button
                        variant="outlined"
                        label={t('newVacancy.cancel')}
                        onClick={() => history.goBack()}
                        iconLeft={() => <ClearTwoToneIcon/>}
                    />
                )}
                renderRight={() => (
                    <React.Fragment>
                        <Button
                            variant="contained"
                            customColor="blue"
                            onClick={handleSave}
                            label={t('newVacancy.placeVacancy')}
                        />
                        <Button
                            variant="contained"
                            customColor="yellow"
                            label={t('newVacancy.previewVacancy')}
                            component={Link}
                            to={`/kansen/${draft.id}/${convertToSlug(draft.title)}`}
                        />
                    </React.Fragment>
                )}
            />
            <Breadcrumbs path={BreadcrumbPath}/>
            <div className={classes.root}>
                <LoaderModal loading={loadingVacancy || mutationLoading}/>
                {mutationError && (
                    <div className={classes.message}>
                        <span>{t('login.errorMessage')}</span>
                    </div>
                )}
                <Grid className={classes.grid} container spacing={4} justify="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography className={classes.title} variant="h2">
                            {t('newVacancy.placeVacancy')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <Typography className={classes.heading}>Algemene informatie</Typography>

                            <Grid container justify="space-between">
                                <Grid item xs={6}>
                                    <InputField
                                        className={classes.input}
                                        initialValue={draft.title}
                                        name="title"
                                        type="text"
                                        label="Functie"
                                        error={false}
                                        form={form}
                                        errorMessage={t('newVacancy.errorTitle')}
                                    />
                                    <SelectField
                                        label="Soort kans"
                                        placeholder="Selecteer"
                                        option={draft.type.id}
                                        name="type"
                                        options={mapToSelect(paginatedVacancyTypes.types, 'description', 'id')}
                                        helper={false}
                                        error={false}
                                        form={form}
                                        errorMessage={t('newVacancy.errorType')}
                                    />
                                    <Grid container justify="space-between">
                                        <Grid item xs={6}>
                                            <SelectField
                                                label="Opleidingsniveau"
                                                placeholder="Selecteer"
                                                option={draft.educationGrade.id}
                                                name="educationGrade"
                                                options={mapToSelect(
                                                    educationGrades.educationGrades,
                                                    'description',
                                                    'id'
                                                )}
                                                helper={false}
                                                form={form}
                                                error={false}
                                                errorMessage={t('newVacancy.errorEducation')}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <InputField
                                                className={classes.input}
                                                initialValue={draft.salaryScale}
                                                name="salaryScale"
                                                placeholder="Selecteer schaal"
                                                type="text"
                                                label="Functieschaal/bedrag"
                                                optional
                                                error={false}
                                                form={form}
                                                errorMessage={t('newVacancy.errorTitle')}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    {cities && (
                                        <AutoCompleteInput
                                            label="Plaats"
                                            placeholder="Plaats"
                                            noOptionsText={t('profileForm.searchCity')}
                                            initialValue={draft.city.id}
                                            name="city"
                                            form={form}
                                            error={false}
                                            errorMessage={t('newVacancy.errorContact')}
                                            data={{
                                                query: GET_CITIES,
                                                response: 'cities'
                                            }}
                                        />

                                        // <SelectField
                                        //     label="Plaats"
                                        //     placeholder="Plaats"
                                        //     helper={false}
                                        //     option={draft.city.id}
                                        //     options={mapToSelect(cities.cities, 'name', 'id')}
                                        //     name="city"
                                        //     form={form}
                                        //     error={false}
                                        //     errorMessage={t('newVacancy.errorContact')}
                                        // />
                                    )}
                                    {contactPersons && (
                                        <SelectField
                                            label="Contactpersoon"
                                            placeholder="Contactpersoon"
                                            helper={false}
                                            option={draft.contactPerson.id}
                                            options={mapToSelect(contactPersons, 'email', 'id')}
                                            name="contactPerson"
                                            form={form}
                                            error={false}
                                            errorMessage={t('newVacancy.errorContact')}
                                        />
                                    )}
                                    <Grid container justify="space-between">
                                        <Grid item xs={6}>
                                            <SelectField
                                                label="Duur"
                                                placeholder="duration"
                                                helper={false}
                                                option={draft.duration.id}
                                                options={mapToSelect(durations.durations, 'description', 'id')}
                                                name="duration"
                                                initialValue={draft.length}
                                                form={form}
                                                error={false}
                                                errorMessage={t('newVacancy.errorLength')}
                                            />
                                        </Grid>
                                        {workingHoursList.workingHoursList && (
                                            <Grid item xs={6}>
                                                <SelectField
                                                    label="Aantal uur per week"
                                                    placeholder="uren"
                                                    helper={false}
                                                    option={draft.workingHoursAWeek.id}
                                                    options={mapToSelect(
                                                        workingHoursList.workingHoursList,
                                                        'value',
                                                        'id'
                                                    )}
                                                    name="workingHoursAWeek"
                                                    initialValue={draft.workingHoursAWeek}
                                                    form={form}
                                                    error={false}
                                                    errorMessage={t('newVacancy.errorHours')}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <Typography className={classes.heading}>Publicatie</Typography>
                            <Grid container justify="space-between">
                                {draft.publicationPeriod &&
                                    draft.publicationPeriod.map((item, idx) => (
                                        <Grid key={idx} container justify="space-between">
                                            <Grid item xs={3}>
                                                <DatePicker
                                                    label="Publicatiedatum"
                                                    placeholder=""
                                                    helper={false}
                                                    name="publicationStart"
                                                    onChange={(val) => handlePeriodChange('startDate', idx, val)}
                                                    disablePast={false}
                                                    initialValue={item.publicationStart}
                                                    error={false}
                                                    errorMessage={t('newVacancy.errorPublicationStart')}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <DatePicker
                                                    label="Sluitingsdatum"
                                                    placeholder=""
                                                    helper={false}
                                                    name="publicationEnd"
                                                    onChange={(val) => handlePeriodChange('endDate', idx, val)}
                                                    disablePast={false}
                                                    initialValue={item.publicationEnd}
                                                    error={false}
                                                    errorMessage={t('newVacancy.errorPublicationEnd')}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <SelectField
                                                    label="Zichtbaarheid"
                                                    placeholder="Zichtbaarheid"
                                                    helper={false}
                                                    option={item.visibility.id}
                                                    options={mapToSelect(
                                                        vacancyVisibilities.vacancyVisibilities,
                                                        'description',
                                                        'id'
                                                    )}
                                                    name="visibilityTypes"
                                                    onChange={(val) => handlePeriodChange('visibility', idx, val)}
                                                    initialValue={item.visibilityTypes}
                                                    error={false}
                                                    errorMessage={t('vacancyForm.errorVisibilityTypes')}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}

                                <Grid item xs={6}>
                                    <Grid container justify="space-between">
                                        <Grid container justify="space-between">
                                            <Grid container>
                                                <Typography onClick={addPublication} className={classes.addBar}>
                                                    <Add/> {t('newVacancy.addNewDates')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <Typography className={classes.heading}>Vakgebieden</Typography>
                            <Typography variant="subtitle1" className={classes.label}>
                                Selecteer één of meerdere vakgebieden
                            </Typography>
                            <Box mt={2}/>
                            <div className={classes.customGrid}>
                                {expertises.expertises &&
                                    expertises.expertises.map((item, idx) => (
                                        <div key={idx} className={classes.gridItem}>
                                            <Checkbox
                                                onChange={() => handleCheckbox(item.value)}
                                                label={item.label}
                                                value={item.value}
                                                checked={draft.expertiseList.indexOf(item.id) !== -1}
                                            />
                                        </div>
                                    ))}
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <Typography className={classes.heading}>Informatie kans</Typography>

                            <Grid container justify="space-between">
                                <Grid item xs={8}>
                                    <Typography className={classes.label}>Upload afbeelding of video</Typography>
                                    <FileUpload label="Bestand kiezen"/>
                                    <RichInputField
                                        className={classes.rich}
                                        initialValue={draft.description}
                                        name="description"
                                        title="Functie omschrijving"
                                        error={false}
                                        form={form}
                                        errorMessage={t('newVacancy.errorRichText')}
                                    />
                                    <Typography className={classes.label}>Upload bijlage</Typography>
                                    <FileUpload label="Bestand kiezen" onSucces={handleFile} onClear={clearFile}/>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        </React.Fragment>
    );
};

export default EditVacancy;
