import React from 'react';
import CustomIcon from './CustomIcon';

const Search = props => {
    return (
        <CustomIcon width="21" height="20" {...props}>
            <path
                d="M20.003 18.585L18.589 20l-4.243-4.242 1.414-1.415 4.243 4.242zM8.003 14c-3.308 0-6-2.69-6-6 0-3.308 2.692-6 6-6s6 2.692 6 6c0 3.31-2.692 6-6 6zm0-14a8 8 0 100 16.001 8 8 0 000-16z"
                id="a"
            />

            <g fill="none" fillRule="evenodd">
                <mask id="b" fill="#fff">
                    <use xlink="#a" />
                </mask>
                <g mask="url(#b)" fill="#FFF">
                    <path d="M0 0h21v20H0z" />
                </g>
            </g>
        </CustomIcon>
    );
};

export default Search;
