import React from 'react';
import CustomIcon from './CustomIcon'

const DocFile = props => (
    <CustomIcon {...props} svgAtts={{ viewBox: '0 0 550.801 550.801'}}>
        <path d="M281 413.575c-18.581 0-29.415 17.687-29.415 41.322 0 23.825 11.19 40.605 29.595 40.605 18.594 0 29.236-17.687 29.236-41.322 0-21.842-10.463-40.605-29.416-40.605zM142.57 413.934c-6.139 0-10.115.538-12.456 1.076v79.586c2.341.549 6.138.549 9.563.549 24.898.18 41.141-13.542 41.141-42.588.19-25.271-14.612-38.623-38.248-38.623z"/>
        <path d="M475.095 131.992c-.032-2.526-.833-5.021-2.568-6.993L366.324 3.694c-.021-.034-.053-.045-.084-.076-.633-.707-1.36-1.29-2.141-1.804-.232-.15-.465-.285-.707-.422-.686-.366-1.393-.67-2.131-.892-.2-.058-.379-.14-.58-.192C359.87.114 359.047 0 358.203 0H97.2C85.292 0 75.6 9.693 75.6 21.601v507.6c0 11.913 9.692 21.601 21.6 21.601h356.4c11.918 0 21.601-9.688 21.601-21.601V133.202c-.001-.406-.064-.804-.106-1.21zM189.854 500.914c-12.814 10.652-32.305 15.704-56.133 15.704-14.262 0-24.366-.907-31.219-1.804V395.35c10.104-1.624 23.28-2.531 37.18-2.531 23.095 0 38.074 4.156 49.803 12.994 12.638 9.387 20.579 24.363 20.579 45.837-.004 23.282-8.481 39.339-20.21 49.264zm89.88 16.432c-36.091 0-57.206-27.253-57.206-61.905 0-36.455 23.28-63.703 59.189-63.703 37.356 0 57.755 27.976 57.755 61.536.001 39.888-24.184 64.072-59.738 64.072zm140.053-22.918c8.3 0 17.508-1.814 22.918-3.972l4.155 21.474c-5.052 2.537-16.421 5.236-31.219 5.236-42.05 0-63.703-26.172-63.703-60.818 0-41.502 29.595-64.604 66.403-64.604 14.26 0 25.091 2.885 29.964 5.416l-5.601 21.832c-5.585-2.342-13.353-4.515-23.093-4.515-21.832 0-38.807 13.179-38.807 40.247.002 24.358 14.44 39.704 38.983 39.704zM97.2 366.752V21.601h250.203v110.515c0 5.961 4.831 10.8 10.8 10.8H453.6l.011 223.836H97.2z"/>
        <path d="M248.023 149.099h78.592c3.945 0 7.151-3.201 7.151-7.153 0-3.952-3.206-7.156-7.151-7.156h-78.592c-3.953 0-7.146 3.204-7.146 7.156-.005 3.952 3.185 7.153 7.146 7.153zM365.059 192.024H169.538c-3.952 0-7.161 3.201-7.161 7.153 0 3.955 3.209 7.151 7.161 7.151h195.521c3.955 0 7.162-3.196 7.162-7.151 0-3.951-3.201-7.153-7.162-7.153zM189.973 163.432l6.626-26.937c1.695-6.539 2.805-12.062 3.829-18.18h.166c.675 6.199 1.695 11.641 3.138 18.18l5.956 26.937h11.222l15.367-57.267h-10.621l-5.946 25.914c-1.529 6.971-2.896 13.505-3.91 19.802h-.177c-.844-6.204-2.036-12.577-3.478-19.375l-5.693-26.335h-10.96l-6.194 25.914c-1.627 7.22-3.251 14.025-4.174 20.05h-.158c-.934-5.69-2.299-13.002-3.747-19.968l-5.439-25.996h-11.132l14.022 57.267h11.303v-.006zM365.059 248.849H169.538c-3.952 0-7.161 3.201-7.161 7.156 0 3.952 3.209 7.153 7.161 7.153h195.521c3.955 0 7.162-3.201 7.162-7.153 0-3.955-3.201-7.156-7.162-7.156zM365.059 307.03H169.538c-3.952 0-7.161 3.206-7.161 7.161 0 3.95 3.209 7.151 7.161 7.151h195.521c3.955 0 7.162-3.201 7.162-7.151 0-3.955-3.201-7.161-7.162-7.161z"/>
    </CustomIcon>
);

export default DocFile;