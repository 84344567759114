import gql from 'graphql-tag';

// Used to fetch the information needed on the vacancy page of the current user
export const GET_CURRENT_USER_HR = gql`
    query currentUser {
        currentUser {
            id
            corporation {
                provinces
            }
        }
    }
`;