import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const CustomIcon = props => {
    const {
        children,
        color = 'primary',
        size = 'default',
        className,
        width = '24',
        height = '24',
        svgAtts
    } = props;

    return (
        <SvgIcon
            color={color}
            fontSize={size}
            preserveAspectRatio="xMidYMid"
            viewBox={`0 0 ${width} ${height}`}
            className={className}
            {...svgAtts}
        >
            {children}
        </SvgIcon>
    );
};

export default CustomIcon;
