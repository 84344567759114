import { TableCell, withStyles } from '@material-ui/core';
import React from 'react';

const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.background
    },
    root: {
        padding: `${theme.spacing(2.5)}px ${theme.spacing(2)}px`,
        '&:first-child': {
            paddingLeft: 0,
            minWidth: '200px'
        }
    }
}))(TableCell);

const TableCellContainer = props => {
    const { children, align = 'left' } = props;

    return <StyledTableCell align={align}>{children}</StyledTableCell>;
};

export default TableCellContainer;
