import React, { useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { useSelector } from 'react-redux';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/styles';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@material-ui/core';
import { Edit, FileCopy, PersonOutline, Public } from '@material-ui/icons';
import ApplyDialog from './ApplyDialog';
import {
    ArrowRight,
    Block,
    Breadcrumbs,
    Button,
    FavoriteButton,
    ImageOrVideo,
    Link,
    Loader,
    LoaderModal,
    RichTextContainer,
    ScrollToTop,
    ShareButtons,
    SubMenu,
    Tooltip
} from 'shared';
import { convertToSlug, formatDate } from 'helpers';
import { DELETE_VACANCY, DUPLICATE_VACANCY, GET_USER, GET_VACANCY, PUBLISH_VACANCY, RESPOND_TO_VACANCY, VIEW_VACANCY } from '../queries';
import { ROLE_NAMES } from 'const';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1440px',
        width: '100%',
        margin: '25px auto',
        [theme.breakpoints.down('sm')]: {
            margin: '0 auto'
        }
    },
    attachment: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': {
            color: theme.palette.secondary.main
        }
    },
    assetRoot: {
        borderRadius: '30px 110px 30px 30px',
        overflow: 'hidden',
        width: '100%',

        maxWidth: '780px',
        margin: '64px 0',
        '&>img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain'
        },
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(4, 0, 0)
        },
        [theme.breakpoints.up('sm')]: {
            height: '433px'
        }
    },
    main: {
        padding: theme.spacing(3, 8, 3, 14)
    },
    title: {
        width: '100%',
        paddingBottom: `${theme.spacing(3)}px`,
        wordBreak: 'break-word',
        margin: '30px 0',
        fontSize: '50px',
        [theme.breakpoints.down('md')]: {
            margin: 0,
            fontSize: '30px'
        }
    },
    logoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.up('md')]: {
            maxWidth: '520px'
        }
    },
    logo: {
        width: '190px',
        height: '150px',
        backgroundSize: 'contain'
    },
    image: {
        width: '100%',
        margin: 0,
        padding: theme.spacing(0, 5),
        marginBottom: theme.spacing(2.5)
    },
    banner: {
        width: '100%',
        padding: `${theme.spacing(2)}px 0`,
        [theme.breakpoints.up('md')]: {
            content: 'url(\'/werkenbijdewoco/banner/Display_banner840_KP.gif\')'
        },
        [theme.breakpoints.down('md')]: {
            content: 'url(\'/werkenbijdewoco/banner/Display_banner350_KP.png\')'
        }
    },
    sideBar: {
        [theme.breakpoints.up('md')]: {
            maxWidth: '520px'
        }
    },
    properties: {
        marginBottom: theme.spacing(2.5),
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexFlow: 'column nowrap'
        }
    },
    prop: {
        flex: '1 1 100%',
        flexDirection: 'column',
        display: 'flex',
        paddingBottom: theme.spacing(2),
        fontSize: '18px',
        color: theme.palette.text.secondaryLight,
        wordBreak: 'break-word',
        hyphens: 'auto',
        [theme.breakpoints.up(1024)]: {
            width: '50%',
            paddingRight: theme.spacing(1),
            paddingBottom: theme.spacing(4)
        }
    },
    propTitle: {
        width: '100%',
        color: theme.palette.primary.main,
        fontWeight: 500
    },
    mentionWrapper: {
        padding: theme.spacing(3, 8, 3, 14),
        display: 'flex'
    },
    mention: {
        borderRadius: '52px',
        paddingBottom: theme.spacing(5),
        paddingTop: theme.spacing(5),
        width: 'auto',
        flex: '0 0 auto',
        margin: '0 auto'
    },
    list: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    item: {
        border: `1px solid ${theme.palette.text.secondaryLight}`,
        borderRadius: '35px',
        color: theme.palette.text.main,
        fontSize: '0.9em',
        margin: theme.spacing(0, 1.3, 1.3, 0),
        padding: theme.spacing(0.2, 1.4),
        [theme.breakpoints.down(1025)]: {
            padding: theme.spacing(1, 2),
            lineHeight: '1.25'
        }
    },
    changeButton: {
        background: '#D0BADA',
        color: theme.palette.text.secondary
    },
    deleteButton: {
        background: '#F6D0D0',
        color: theme.palette.text.secondary
    },
    copyButton: {
        background: '#D4ECF7',
        color: theme.palette.text.secondary
    },
    reactionButton: {
        background: '#FFE88B',
        color: theme.palette.text.secondary
    },
    headerLeft: {
        display: 'flex'
    },
    draft: {
        color: theme.palette.text.secondary,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    documents: {
        marginBottom: theme.spacing(2.5),
        '& a': {
            color: theme.palette.primary.main
        }
    },
    '@keyframes top': {
        '0%': {
            opacity: 1,
            transform: 'translateY(0)'
        },
        '50%': {
            opacity: 1,
            transform: 'translateY(0)'
        },
        '75%': {
            transform: 'translateY(-100%)',
            opacity: 0
        },
        '100%': {
            transform: 'translateY(-100%)',
            opacity: 0
        }
    },
    '@keyframes bottom': {
        '0%': {
            opacity: 0,
            transform: 'translateY(100%)'
        },
        '50%': {
            opacity: 0,
            transform: 'translateY(100%)'
        },
        '75%': {
            opacity: 1,
            transform: 'translateY(0)',
            color: theme.palette.primary.main
        },
        '100%': {
            opacity: 1,
            transform: 'translateY(0)'
        }
    },
    previewModus: {
        position: 'relative',
        overflow: 'hidden'
    },
    previewModusLabel: {
        display: 'block',
        animation: `$top 4s infinite ease-in-out`,
        '&:nth-child(2)': {
            position: 'absolute',
            top: 0,
            left: 0,
            transform: 'translateX(100%)',
            animation: `$bottom 4s infinite ease-in-out`
        }
    },
    publishButton: {
        background: theme.palette.secondary.light,
        color: theme.palette.text.secondary
    },
    favoriteButton: {
        [theme.breakpoints.down(767)]: {
            minWidth: '50vw',
            textAlign: 'center'
        }
    },
    aButton: {
        [theme.breakpoints.down(767)]: {
            minWidth: '50vw'
        }
    },
    disclaimer: {
        background: '#E9F5FB',
        marginTop: theme.spacing(10),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(6),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(7),
        '&>h6': {
            fontSize: 18,
            marginBottom: theme.spacing(1)
        },
        '&>p': {
            fontSize: 14,
            lineHeight: '24px'
        }
    },
    disclaimerLink: {
        textDecoration: 'none',
        color: theme.palette.text.hover,
        transition: 'color 300ms',
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    blockLink: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        fontSize: '1.125rem',
        fontWeight: 500,
        marginTop: theme.spacing(4),
        marginLeft: 'auto',
        color: theme.palette.primary.main,
        [theme.breakpoints.up('md')]: {
            '&:hover': {
                '& $linkIcon': {
                    transform: 'translateX(10px)'
                }
            }
        }
    },
    linkIcon: {
        width: 20,
        marginLeft: theme.spacing(2),
        transition: 'transform 200ms'
    }
}));

const VacancyDetail = () => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { id } = useParams();
    const history = useHistory();
    const location = useLocation();

    const [motivation, setMotivation] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [isOwner, setIsOwner] = useState(false);
    const [active, setActive] = useState(false);
    const [responded, setResponded] = useState(false);
    const [externalVacancy, setExternalVacancy] = useState(false);

    const loggedIn = useSelector((state) => state.auth.isLoggedIn);
    const isHrUser = useSelector((state) => state.auth.roles.includes(ROLE_NAMES.HR_USER));
    const isAdminUser = useSelector((state) => state.auth.roles.includes(ROLE_NAMES.ADMIN));
    const user = useSelector((state) => state.auth.user);
    const likedVacancies = useSelector((state) => state.auth.user.likedVacancies);
    const respondedVacancys = useSelector((state) => state.auth.user.respondedVacancies);
    const { enqueueSnackbar } = useSnackbar();

    const { loading, data = {} } = useQuery(GET_VACANCY, {
        variables: {
            id: id
        }
    });
    const { vacancy = {} } = data;
    const { loading: userLoading, data: userData = {} } = useQuery(GET_USER);
    const { currentUser = {} } = userData;

    const [deleteVacancy, { loading: loadingMutation }] = useMutation(DELETE_VACANCY);
    const [respondToVacancy, { loading: loadingRespond }] = useMutation(RESPOND_TO_VACANCY);
    const [duplicateVacancy, { loading: loadingDuplicate }] = useMutation(DUPLICATE_VACANCY);
    const [viewVacancy] = useMutation(VIEW_VACANCY);
    const [publishVacancy] = useMutation(PUBLISH_VACANCY);

    const BreadcrumbPath = useMemo(
        () => [
            {
                title: 'Kansen',
                link: '/kansen'
            },
            {
                title: vacancy ? vacancy.title : '',
                link: `/kansen/${vacancy ? vacancy.id : 0}/${convertToSlug(vacancy ? vacancy.title : '')}`
            }
        ],
        [vacancy]
    );

    useEffect(() => {
        viewVacancy({
            variables: {
                input: {
                    vacancyId: id
                }
            }
        });
    }, [id, viewVacancy]);

    useEffect(() => {
        if (likedVacancies) {
            likedVacancies.forEach((item) => {
                if (item.id === parseInt(id)) {
                    setActive(true);
                }
            });
        }
    }, [id, likedVacancies]);

    useEffect(() => {
        if (respondedVacancys) {
            respondedVacancys.forEach((item) => {
                if (item.id.vacancyId === parseInt(id)) {
                    setResponded(true);
                }
            });
        }
    }, [id, respondedVacancys]);

    useEffect(() => {
        if (user && vacancy && user.corporation && vacancy.corporation) {
            if (user.corporation.domain === vacancy.corporation.domain) {
                setIsOwner(true);
            } else {
                setIsOwner(false);
            }
        }
    }, [user, vacancy]);

    useEffect(() => {
        if (
            vacancy &&
            (vacancy.originURL ||
                vacancy.crawled ||
                (vacancy.contactPerson &&
                    parseFloat(vacancy.contactPerson.id) === parseFloat(process.env.REACT_APP_EXTERNAL_CONTACT_ID)))
        ) {
            setExternalVacancy(true);
        }
    }, [vacancy]);

    const handleResponePopUp = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
        setMotivation(null);
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const handleSave = () => {
        publishVacancy({
            variables: {
                input: {
                    id: parseInt(id)
                }
            }
        }).then((r) => {
            history.push('/hr-kansen');
            enqueueSnackbar({
                variant: 'success',
                message: t('newVacancy.saved')
            });
        });
    };

    const handleDeleteVacancy = () => {
        deleteVacancy({
            variables: {
                input: {
                    id: parseInt(id)
                }
            }
        });
        history.push('/hr-kansen');
    };

    const handleSendResponse = (data) => {
        respondToVacancy({
            variables: {
                input: {
                    motivation: motivation,
                    vacancyId: parseInt(id),
                    cvId: data.sendCv ? currentUser.careerProfile.cv.id : data.cvId || null,
                    videoURL: data.youtubeId,
                    attachments: data.documents
                }
            }
        })
            .then((r) => {
                if (!r.errors) {
                    enqueueSnackbar({
                        variant: 'success',
                        message: t('snackbar.reactionSucces')
                    });
                }
                handleClose();
            })
            .catch((e) => console.log('e'));
    };

    const handleCopyVacancy = () => {
        duplicateVacancy({
            variables: {
                input: {
                    id: id
                }
            }
        }).then((resp) => {
            if (resp) {
                enqueueSnackbar({
                    variant: 'success',
                    message: t('snackbar.copyVacancy')
                });
                history.push(
                    `/hr-kansen/bewerken/${resp.data.duplicateVacancy.vacancy.id}/${convertToSlug(
                        resp.data.duplicateVacancy.vacancy.title
                    )}`
                );
            }
        });
    };

    if (loading) return <Loader />;
    if (
        !vacancy ||
        (vacancy && vacancy.visibility && vacancy.visibility.id === 5 && !isHrUser) ||
        (vacancy &&
            vacancy.visibility &&
            vacancy.visibility.id === 5 &&
            isHrUser &&
            user &&
            vacancy.contactPerson &&
            user.id !== vacancy.contactPerson.id)
    ) {
        return (
            <Grid container className={classes.root} spacing={7} justify='space-between'>
                <Grid item xs={7} className={classes.main}>
                    {t('vacancyDetail.vacancyNotFound')}
                </Grid>
            </Grid>
        );
    }
    return (
        <React.Fragment>
            <Helmet>
                <link rel='canonical' href={window.location.origin + location.pathname} />
                <meta property='og:title' content={`Flow - ${vacancy.title}`}></meta>
                <meta property='og:description' content={`Flow - ${vacancy.title}`}></meta>
                <meta property='og:url' content={window.location.origin + location.pathname}></meta>
                <meta property='og:site_name' content={window.location.origin}></meta>
            </Helmet>
            {isAdminUser || (isHrUser && isOwner) ? (
                <SubMenu
                    renderLeft={() => (
                        <div className={classes.headerLeft}>
                            {vacancy.draft ? (
                                <div className={classes.draft}>
                                    <Typography variant='body2' className={classes.previewModus}>
                                        <Trans>
                                            <span className={classes.previewModusLabel}>
                                                {t('vacancyDetail.previewModus')}
                                            </span>
                                            <span className={classes.previewModusLabel}>
                                                {t('vacancyDetail.previewModus')}
                                            </span>
                                        </Trans>
                                    </Typography>
                                </div>
                            ) : (
                                <ShareButtons description={vacancy.description} />
                            )}
                        </div>
                    )}
                    renderRight={() => (
                        <>
                            <Button
                                label={t('vacancyDetail.copyButton')}
                                variant='contained'
                                className={classes.copyButton}
                                iconLeft={() => <FileCopy />}
                                onClick={handleCopyVacancy}
                            />
                            <Button
                                component={Link}
                                to={`/hr-kansen/bewerken/${id}/${convertToSlug(vacancy.title)}`}
                                label={t('vacancyDetail.changeButton')}
                                variant='contained'
                                className={classes.changeButton}
                                iconLeft={() => <Edit />}
                            />
                            {!vacancy.draft ? (
                                <Button
                                    component={Link}
                                    to={`/kansen/${id}/${convertToSlug(vacancy.title)}/kandidaten`}
                                    label={`${t('vacancyDetail.reactionButton')} (${
                                        vacancy.responses ? vacancy.responses.length : 0
                                    })`}
                                    variant='contained'
                                    className={classes.reactionButton}
                                    iconLeft={() => <PersonOutline />}
                                />
                            ) : (
                                <Button
                                    variant='contained'
                                    className={classes.publishButton}
                                    onClick={handleSave}
                                    iconLeft={() => <Public />}
                                    label={
                                        vacancy.draft || vacancy.draft === undefined
                                            ? t('newVacancy.placeVacancy')
                                            : t('newVacancy.placeVacancyAlt')
                                    }
                                />
                            )}
                        </>
                    )}
                />
            ) : (
                <SubMenu
                    renderLeft={() => <ShareButtons />}
                    renderRight={() =>
                        loggedIn &&
                        likedVacancies &&
                        !isHrUser &&
                        !responded && (
                            <React.Fragment>
                                <FavoriteButton
                                    label={t('vacancyDetail.saveButtonLabel')}
                                    success={t('vacancyDetail.saveButtonText')}
                                    id={id}
                                    liked={active}
                                    classes={{ rootButton: classes.favoriteButton }}
                                />
                                {externalVacancy ? (
                                    <Button
                                        classes={{ root: classes.aButton }}
                                        variant='contained'
                                        color='secondary'
                                        label={t('Bekijk kans')}
                                        href={vacancy.originURL}
                                        target='_blank'
                                        rel='noopener noreferrer'
                                    >
                                        Bekijk kans
                                    </Button>
                                ) : (
                                    <Button
                                        classes={{ root: classes.aButton }}
                                        variant='contained'
                                        color='secondary'
                                        label={t('vacancyDetail.reactButton')}
                                        onClick={handleResponePopUp}
                                    />
                                )}
                            </React.Fragment>
                        )
                    }
                />
            )}
            <Breadcrumbs path={BreadcrumbPath} />
            <LoaderModal loading={loading || loadingMutation || loadingRespond || loadingDuplicate || userLoading} />
            {!loading && !userLoading && vacancy && (
                <Grid container className={classes.root} spacing={7} justify='space-between'>
                    <Grid item xs={12} md={7} className={classes.main}>
                        <Typography className={classes.title} variant='h1'>
                            {vacancy.title}
                        </Typography>
                        {vacancy.description && (
                            <React.Fragment>
                                <RichTextContainer format={true} content={vacancy.description} />

                                {externalVacancy && (
                                    <Block variant='blue' smaller='smaller' className={classes.disclaimer}>
                                        <Typography variant='h6'>{t('vacancyDetail.disclaimer.title')}</Typography>
                                        <Typography variant='body2'>
                                            {t('vacancyDetail.disclaimer.firstTextHalf')}
                                            <Link
                                                to={t('vacancyDetail.disclaimer.contactHref')}
                                                className={classes.disclaimerLink}
                                            >
                                                {t('vacancyDetail.disclaimer.contactTitle')}
                                            </Link>
                                            {t('vacancyDetail.disclaimer.secondTextHalf')}
                                        </Typography>
                                        <br />
                                        <Typography variant='body2'>
                                            {t('vacancyDetail.disclaimer.firstExternalSourceTextHalf')}
                                            <Link
                                                className={classes.disclaimerLink}
                                                to={{
                                                    pathname: '/login',
                                                    state: {
                                                        referrer: location.pathname,
                                                        loginParam: location.pathname
                                                    }
                                                }}
                                            >
                                                {t('vacancyDetail.disclaimer.loginTitle')}
                                            </Link>
                                            {t('vacancyDetail.disclaimer.secondExternalSourceTextHalf')}
                                        </Typography>
                                    </Block>
                                )}

                                {!externalVacancy && (
                                    <Block variant='blue' smaller='smaller' className={classes.disclaimer}>
                                        <Typography variant='h6'>{t('vacancyDetail.respond.title')}</Typography>
                                        <Typography variant='body2'>{t('vacancyDetail.respond.text')}</Typography>

                                        {!loggedIn && (
                                            <Link
                                                className={classes.blockLink}
                                                to={{
                                                    pathname: '/login',
                                                    state: {
                                                        referrer: location.pathname,
                                                        loginParam: location.pathname
                                                    }
                                                }}
                                            >
                                                {t('vacancyDetail.respond.loginTitle')}
                                                <ArrowRight className={classes.linkIcon} />
                                            </Link>
                                        )}
                                    </Block>
                                )}

                                {vacancy.apiKeys && vacancy.apiKeys.length !== 0 && (
                                    <a
                                        href="https://werkenbijdewoco.nl"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={classes.banner}
                                    ></a>
                                )}
                            </React.Fragment>
                        )}
                        {vacancy.imageOrVideo && vacancy.imageOrVideo.url && (
                            <div className={classes.assetRoot}>
                                <ImageOrVideo url={vacancy.imageOrVideo.url} className={classes} />
                            </div>
                        )}
                        {vacancy.videoURL && (
                            <div className={classes.assetRoot}>
                                <ImageOrVideo url={vacancy.videoURL} className={classes} type='youtube' />
                            </div>
                        )}
                        {/* <div className={classes.mentionWrapper}> */}
                        <ScrollToTop />
                        {/* </div> */}
                    </Grid>
                    <Grid item xs={12} md={5} className={classes.logoWrapper}>
                        {vacancy.corporation && vacancy.corporation.logo && vacancy.corporation.logo.url && (
                            <img
                                className={classes.image}
                                src={vacancy.corporation.logo.url}
                                alt={vacancy.corporation.name}
                            />
                        )}

                        <div className={classes.sideBar}>
                            <Block className={classes.properties}>
                                <span className={classes.prop}>
                                    <span className={classes.propTitle}>{t('vacancyDetail.titles.type')}</span>
                                    {vacancy.type ? (
                                        <span>
                                            {vacancy.type.description}
                                            {vacancy.type.explanation && <Tooltip title={vacancy.type.explanation} />}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </span>
                                <span className={classes.prop}>
                                    <span className={classes.propTitle}>
                                        {t('vacancyDetail.titles.educationLevel')}
                                    </span>
                                    {vacancy.educationGrade ? vacancy.educationGrade.description : ''}
                                </span>
                                <span className={classes.prop}>
                                    <span className={classes.propTitle}>{t('vacancyDetail.titles.salaryScale')}</span>
                                    {vacancy.salaryScale ? vacancy.salaryScale.description : ''}
                                </span>
                                <span className={classes.prop}>
                                    <span className={classes.propTitle}>{t('vacancyDetail.titles.city')}</span>
                                    {vacancy.city ? vacancy.city.name : ''}
                                </span>
                                <span className={classes.prop}>
                                    <span className={classes.propTitle}>{t('vacancyDetail.titles.corporation')}</span>
                                    {externalVacancy ? (
                                        <span>-</span>
                                    ) : (
                                        <span>
                                            {vacancy.corporation
                                                ? vacancy.originURL
                                                    ? '-'
                                                    : vacancy.corporation.name
                                                : ''}
                                        </span>
                                    )}
                                </span>
                                <span className={classes.prop}>
                                    <span className={classes.propTitle}>{t('vacancyDetail.titles.duration')}</span>
                                    {vacancy.duration ? vacancy.duration.description : ''}
                                </span>
                                <span className={classes.prop}>
                                    <span className={classes.propTitle}>{t('vacancyDetail.titles.hoursPerWeek')}</span>
                                    {vacancy.workingHoursAWeek ? vacancy.workingHoursAWeek.value : t('general.unknown')}
                                </span>
                                <span className={classes.prop}>
                                    <span className={classes.propTitle}>{t('vacancyDetail.titles.closingDate')}</span>
                                    {vacancy.activePublicationPeriod
                                        ? formatDate(vacancy.activePublicationPeriod.endDate)
                                        : t('vacancyDetail.notActive')}
                                </span>
                                <span>
                                    {vacancy.expertiseList && vacancy.expertiseList.length > 0 && (
                                        <>
                                            <span className={classes.propTitle}>
                                                {t('vacancyDetail.titles.expertises')}
                                            </span>
                                            <Box className={classes.list}>
                                                {vacancy.expertiseList.map((item) => {
                                                    return (
                                                        <Typography key={item.id} className={classes.item}>
                                                            {item.description}
                                                        </Typography>
                                                    );
                                                })}
                                            </Box>
                                        </>
                                    )}
                                </span>
                            </Block>

                            {vacancy.attachments && vacancy.attachments.length > 0 && (
                                <Block className={classes.documents}>
                                    <span className={classes.propTitle}>{t('vacancyDetail.titles.attachments')}</span>
                                    <ul>
                                        {vacancy.attachments.map(({ id, name, url }) => (
                                            <li key={id}>
                                                <Link
                                                    className={classes.attachment}
                                                    to={url}
                                                    target='_blank'
                                                    download={name}
                                                    rel='noopener noreferrer'
                                                >
                                                    {name}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </Block>
                            )}

                            {vacancy.activePublicationPeriod && vacancy.activePublicationPeriod.visibility.id === 1 && (
                                <Block variant='blue' smaller='smaller'>
                                    {formatDate(vacancy.activePublicationPeriod.endDate)}{' '}
                                    {t('vacancyDetail.internalMessage')}
                                </Block>
                            )}
                        </div>
                    </Grid>
                </Grid>
            )}
            {!loading && !vacancy && (
                <Grid container className={classes.root} spacing={7} justify='space-between'>
                    <Grid item xs={7} className={classes.main}>
                        {t('vacancyDetail.vacancyNotFound')}
                    </Grid>
                </Grid>
            )}
            <ApplyDialog
                user={currentUser}
                open={isOpen}
                onClose={handleClose}
                onBlur={(event) => setMotivation(event.target.value)}
                onClick={handleSendResponse}
            />
            <Dialog
                open={confirmOpen}
                onClose={handleConfirmClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
            >
                <DialogTitle id='alert-dialog-title'>label={t('vacancyDetail.deleteTitle')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        label={t('vacancyDetail.deleteText')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmClose} color='primary' label={t('vacancyDetail.cancleButton')} />
                    <Button
                        onClick={handleDeleteVacancy}
                        color='primary'
                        variant='contained'
                        autoFocus
                        label={t('vacancyDetail.deleteButton')}
                    />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default VacancyDetail;
