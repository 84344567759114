import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/Root';
import './locales/i18n';
import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/browser';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
});

const tagManagerArgs = {
    gtmId: 'GTM-WHGJFCZ',
    dataLayerName: 'PageDataLayer'
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<Root />, document.getElementById('root'));
