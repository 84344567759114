import React, { useEffect, useReducer, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import smoothscroll from 'smoothscroll-polyfill';

import { useMutation, useQuery } from '@apollo/react-hooks';
import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Paper,
    Typography
} from '@material-ui/core';
import { Add, ClearTwoTone, DeleteOutline, FileCopy, Public, Visibility } from '@material-ui/icons';
import { useStyles } from './styles';

import {
    AutoCompleteInput,
    Breadcrumbs,
    Button,
    Checkbox,
    CheckboxGroup,
    FileUpload,
    InputField,
    Loader,
    LoaderModal,
    RichInputField,
    SelectField,
    SubMenu
} from 'shared';

import {
    DELETE_VACANCY,
    DUPLICATE_VACANCY,
    EDIT_VACANCY,
    GET_CITIES,
    GET_CONTACT_PERSONS,
    GET_DURATIONS,
    GET_EDUCATION_GRADES,
    GET_SALARY_SCALES,
    GET_VACANCY,
    GET_VACANCY_TYPES,
    GET_VISIBILITIES,
    GET_WORKING_HOURS,
    SET_VACANCY,
    UPLOAD_FILE
} from '../queries';

import { useForm, useUploadFile } from 'hooks';

import { convertToSlug, isUndefinedOrNullOrEmptyString, mapToSelect } from 'helpers';
import { EXPERTISES } from 'api';
import { PublicationBlock } from './parts';
import { GET_CURRENT_USER_HR } from './queries';
import { STRINGS } from '../../../const';

smoothscroll.polyfill();

const BreadcrumbPath = [
    {
        title: 'Kansen',
        link: '/hr-kansen'
    },
    {
        title: 'Kans plaatsen',
        link: '/hr-kansen/nieuwe-kans'
    }
];

const NewVacancy = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const history = useHistory();
    const { id: vacancyId } = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [uploadFiles] = useUploadFile(UPLOAD_FILE);

    const [draft, setDraft] = useState({
        publicationPeriod: [
            {
                endDate: '',
                startDate: '',
                visibility: ''
            }
        ]
    });
    const [confirmOpen, setConfirmOpen] = useState(false);

    const [uploadFile] = useMutation(UPLOAD_FILE);
    const [setVacancy, { loading: mutationLoading, error: mutationError }] = useMutation(SET_VACANCY);
    const [updateVacancy, { loading: loadingUpdateVacancy }] = useMutation(EDIT_VACANCY);
    const [duplicateVacancy, { loading: loadingDuplicateVacancy }] = useMutation(DUPLICATE_VACANCY);
    const [deletVacancy] = useMutation(DELETE_VACANCY);

    const { data: workingData = {} } = useQuery(GET_WORKING_HOURS);
    const { data: expertisesData = {} } = useQuery(EXPERTISES);
    const { data: typesData = {} } = useQuery(GET_VACANCY_TYPES);
    const { data: contactData = {} } = useQuery(GET_CONTACT_PERSONS);
    const { data: educationData = {} } = useQuery(GET_EDUCATION_GRADES);
    const { data: durationData = {} } = useQuery(GET_DURATIONS);
    const { data: visibilityData = {} } = useQuery(GET_VISIBILITIES);
    const { data: cityData = {} } = useQuery(GET_CITIES, {
        variables: {
            filter: {}
        }
    });
    const { data: salaryScaleData = {} } = useQuery(GET_SALARY_SCALES);
    const { loading: loadingVacancy, data: vacancyData = {} } = useQuery(GET_VACANCY, {
        variables: {
            id: vacancyId || -1
        },
        onCompleted: (data) => {
            if (data && data.vacancy && !isUndefinedOrNullOrEmptyString(data.vacancy.originURL)) {
                // This vacancy originated from the crawler, expertiseList is NOT mandatory
                setFormSettings({
                    type: 'add',
                    data: {
                        expertiseList: { required: false }
                    }
                });
            }
        }
    });
    const { data: currentUserData = {} } = useQuery(GET_CURRENT_USER_HR, {
        fetchPolicy: 'no-cache'
    });
    const { currentUser = {} } = currentUserData;
    // Noord-Brabant (because of this ticket: https://doopcompany.atlassian.net/browse/FLOW-485)
    const showVisibleInApiOptions = currentUser?.corporation?.provinces?.some((item) => item === STRINGS.NOORD_BRABANT || item === STRINGS.ZUID_HOLLAND);

    const { workingHoursList = [] } = workingData;
    const { expertises = [] } = expertisesData;
    const { paginatedVacancyTypes = [] } = typesData;
    const { contactPersons = [] } = contactData;
    const { educationGrades = [] } = educationData;
    const { durations = [] } = durationData;
    const { vacancyVisibilities = [] } = visibilityData;
    const { cities = [] } = cityData;
    const { salaryScales = [] } = salaryScaleData;
    const vacancy = vacancyData?.vacancy ?? {};

    const [formSettings, setFormSettings] = useReducer(
        (state, action) => {
            if (action.type === 'add') return { ...state, ...action.data };
            if (action.type === 'remove') {
                const { [action.data]: data, ...otherState } = state;
                return { ...otherState };
            }
            return state;
        },
        {
            'startDate-0': { required: true },
            'endDate-0': { required: true },
            'visibility-0': { required: true }
        }
    );

    const [form, submitForm] = useForm({
        title: { required: true },
        type: { required: true },
        contactPerson: { required: true },
        description: { required: true },
        duration: { required: true },
        educationGrade: { required: true },
        expertiseList: { required: true },
        salaryScale: { required: true },
        workingHoursAWeek: { required: false },
        visibleInApi: { required: false },
        ...formSettings
    });

    useEffect(() => {
        if (!loadingVacancy && vacancyData && vacancyData.vacancy) {
            const newPeriods = [];
            const { vacancy: v = {} } = vacancyData;
            if (v.attachments && v.attachments.length) {
                v.attachments.forEach((attachment) => {
                    form.onFieldChange({
                        key: `attachments-${attachment.name}`,
                        value: attachment.id
                    });
                });
            }

            if (v.publicationPeriod && v.publicationPeriod.length) {
                v.publicationPeriod.forEach((item, idx) => {
                    setFormSettings({
                        type: 'add',
                        data: {
                            [`startDate-${idx}`]: { required: true },
                            [`endDate-${idx}`]: { required: true },
                            [`visibility-${idx}`]: { required: true }
                        }
                    });
                    const { visibility = {}, ...otherProps } = item;

                    newPeriods.push({
                        ...otherProps,
                        visibility: visibility.id || null
                    });
                });
                setDraft((draft) => ({
                    ...draft,
                    publicationPeriod: newPeriods
                }));
            }
            if (v.imageOrVideo) {
                setDraft((draft) => ({
                    ...draft,
                    imageOrVideo: v.imageOrVideo.id
                }));
            }
            if (v.apiKeys && v.apiKeys.length !== 0) {
                setDraft((draft) => ({
                    ...draft,
                    visibleInApi: true
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vacancyData]);

    const handleSave =
        (preview = true) =>
        () => {
            submitForm()
                .then((resp) => {
                    const temp = { ...formSettings };
                    const attachments = [];
                    const fields = { ...resp };
                    for (const key in temp) {
                        delete fields[key];
                    }

                    for (const key in fields) {
                        if (key.indexOf('attachment') !== -1) {
                            attachments.push(resp[key]);
                            delete fields[key];
                        }
                    }

                    const variables = {
                        ...fields,
                        draft: preview,
                        attachments,
                        expertiseList: resp.expertiseList,
                        apiKeyWhitelist: resp.visibleInApi ? [1] : [], // Change to correct non-hardcoded values if there is more than one API key.
                        city: resp.city ? resp.city.id || null : null,
                        workingHoursAWeek: isUndefinedOrNullOrEmptyString(resp.workingHoursAWeek)
                            ? null
                            : resp.workingHoursAWeek
                    };

                    if (draft.imageOrVideo || draft.imageOrVideo === null) variables.imageOrVideo = draft.imageOrVideo;
                    if (draft.publicationPeriod && draft.publicationPeriod.length)
                        variables.publicationPeriod = draft.publicationPeriod;

                    if (vacancyId) variables.id = vacancyId;

                    // Delete temp field to handle APIKeys
                    delete variables.visibleInApi;

                    if (vacancyId) {
                        updateVacancy({
                            variables: {
                                input: variables
                            }
                        }).then((r) => {
                            if (!r.errors) {
                                if (preview) {
                                    history.push(
                                        `/kansen/${r.data.updateVacancy.vacancy.id}/${convertToSlug(
                                            r.data.updateVacancy.vacancy.title
                                        )}`
                                    );
                                    handleSnackbar(r, t('newVacancy.savedAsConcept'));
                                } else {
                                    history.push(
                                        `/kansen/${r.data.updateVacancy.vacancy.id}/${convertToSlug(
                                            r.data.updateVacancy.vacancy.title
                                        )}`
                                    );
                                    handleSnackbar(r, '');
                                }
                            }
                        });
                    } else {
                        setVacancy({
                            variables: {
                                input: variables
                            }
                        }).then((r) => {
                            if (!r.errors) {
                                if (preview) {
                                    handleSnackbar(r, t('newVacancy.savedAsConcept'));
                                } else {
                                    handleSnackbar(r);
                                }
                                history.push(
                                    `/kansen/${r.data.createVacancy.vacancy.id}/${convertToSlug(
                                        r.data.createVacancy.vacancy.title
                                    )}`
                                );
                            }
                        });
                    }
                })
                .catch(() => {
                    setTimeout(() => {
                        const muiError = document.querySelector('.Mui-error');
                        muiError &&
                            muiError.scrollIntoView({
                                behavior: 'smooth',
                                block: 'center'
                            });
                    }, 100);
                });
        };

    const handleSnackbar = (result, message) => {
        if (!result.errors) {
            enqueueSnackbar({
                variant: 'success',
                message: message || t('newVacancy.saved')
            });
        } else {
            enqueueSnackbar({
                variant: 'error',
                message: t('newVacancy.errorMessage')
            });
        }
    };

    const handlePreviewClick = (isDraft) => () => {
        if (!isDraft && vacancyId) history.push(`/kansen/${vacancyId}/${convertToSlug(vacancy.title)}`);
        if (isDraft) handleSave(true)();
    };

    const handleFile = (name) => (file) => {
        if (file) {
            uploadFile({
                variables: {
                    file: file
                },
                context: {
                    hasUpload: true
                }
            })
                .then((response) => {
                    if (!response.errors) {
                        if (
                            response.data &&
                            response.data.uploadFile &&
                            response.data.uploadFile.asset &&
                            response.data.uploadFile.asset.id
                        ) {
                            const fileId = response.data.uploadFile.asset.id;

                            setDraft((draft) => ({
                                ...draft,
                                [name]: fileId
                            }));
                        }
                    }
                })
                .catch((error) => {
                    console.warn('uploadFile error: ', error);
                });
        }
    };

    const clearFile = (name) => () => {
        if (name) {
            setDraft((draft) => ({
                ...draft,
                [name]: null
            }));
        }
    };

    const handleFiles = () => (files) => {
        if (files && files.length) {
            const { fields = {} } = form;

            files.forEach((file, idx) => {
                if (!fields[`attachments-${file.name}`]) {
                    uploadFiles(file, `attachments-${file.name}`, form);
                }
            });
        }
    };

    const clearFiles = () => (data) => {
        form.onRemove(`attachments-${data.name}`);
    };

    const addPublication = () => {
        const newPeriods = draft.publicationPeriod;

        setFormSettings({
            type: 'add',
            data: {
                [`startDate-${newPeriods.length}`]: { required: true },
                [`endDate-${newPeriods.length}`]: { required: true },
                [`visibility-${newPeriods.length}`]: { required: true }
            }
        });

        newPeriods.push({
            endDate: '',
            startDate: '',
            visibility: ''
        });
        setDraft((draft) => ({
            ...draft,
            publicationPeriod: newPeriods
        }));
    };

    const removePublication = (data) => {
        setFormSettings({
            type: 'remove',
            data: `startDate-${data}`
        });
        setFormSettings({
            type: 'remove',
            data: `endDate-${data}`
        });
        setFormSettings({
            type: 'remove',
            data: `visibility-${data}`
        });
        form.onRemove(`startDate-${data}`);
        form.onRemove(`endDate-${data}`);
        form.onRemove(`visibility-${data}`);

        const newPeriods = draft.publicationPeriod;
        newPeriods.splice(data, 1);

        setDraft((draft) => ({
            ...draft,
            publicationPeriod: newPeriods
        }));
    };

    const handlePeriodChange = (obj) => {
        const periods = [...draft.publicationPeriod];
        const period = periods[obj.idx];
        period[obj.name] = obj.value;

        setDraft((draft) => ({
            ...draft,
            publicationPeriod: periods
        }));
    };

    const handleDelete = () => {
        setConfirmOpen(true);
    };

    const deleteVacancy = () => {
        deletVacancy({
            variables: {
                input: {
                    id: parseInt(vacancyId)
                }
            }
        }).then((resp) => {
            enqueueSnackbar({
                variant: 'success',
                message: t('newVacancy.deleted')
            });
        });
        history.push('/hr-kansen');
    };

    const handleConfirmClose = () => {
        setConfirmOpen(false);
    };

    const handleCopyVacancy = () => {
        duplicateVacancy({
            variables: {
                input: {
                    id: vacancyId
                }
            }
        }).then((resp) => {
            if (resp) {
                enqueueSnackbar({
                    variant: 'success',
                    message: t('snackbar.copyVacancy')
                });
                history.push(
                    `/hr-kansen/bewerken/${resp.data.duplicateVacancy.vacancy.id}/${convertToSlug(
                        resp.data.duplicateVacancy.vacancy.title
                    )}`
                );
            }
        });
    };

    if (loadingVacancy) return <LoaderModal loading={true} />;
    return (
        <React.Fragment>
            <SubMenu
                renderLeft={() => (
                    <React.Fragment>
                        <Button
                            variant="outlined"
                            label={t('newVacancy.cancel')}
                            onClick={() => history.goBack()}
                            iconLeft={() => <ClearTwoTone />}
                        />
                        {vacancyId && (
                            <Button
                                label={t('vacancyDetail.removeButton')}
                                variant="contained"
                                className={classes.deleteButton}
                                onClick={handleDelete}
                                iconLeft={() => <DeleteOutline />}
                            />
                        )}
                    </React.Fragment>
                )}
                renderRight={() => (
                    <React.Fragment>
                        {vacancyId && (
                            <Button
                                label={t('vacancyDetail.copyButton')}
                                variant="contained"
                                className={classes.copyButton}
                                iconLeft={() => <FileCopy />}
                                onClick={handleCopyVacancy}
                                loading={loadingDuplicateVacancy}
                                disabled={loadingDuplicateVacancy}
                            />
                        )}
                        <Button
                            variant="contained"
                            className={classes.publishButton}
                            loading={loadingUpdateVacancy}
                            disabled={loadingUpdateVacancy}
                            onClick={handleSave(false)}
                            iconLeft={() => <Public />}
                            label={
                                vacancy.draft || vacancy.draft === undefined
                                    ? t('newVacancy.placeVacancy')
                                    : t('newVacancy.placeVacancyAlt')
                            }
                        />
                        <Button
                            variant="contained"
                            customColor="yellow"
                            className={classes.previewButton}
                            iconLeft={() => <Visibility />}
                            label={
                                vacancy.draft || vacancy.draft === undefined
                                    ? t('newVacancy.previewVacancy')
                                    : t('newVacancy.previewVacancyAlt')
                            }
                            onClick={handlePreviewClick(vacancy.draft === undefined ? true : vacancy.draft)}
                        />
                    </React.Fragment>
                )}
            />
            <Breadcrumbs path={BreadcrumbPath} />

            <div className={classes.root}>
                {mutationLoading && <Loader />}
                {mutationError && (
                    <div className={classes.message}>
                        <span>{t('login.errorMessage')}</span>
                    </div>
                )}
                <Grid className={classes.grid} container spacing={4} justify="center" alignItems="center">
                    <Grid item xs={12}>
                        <Typography className={classes.title} variant="h2">
                            {vacancy ? vacancy.title || t('newVacancy.title') : t('newVacancy.title')}
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <Typography className={classes.heading}>{t('newVacancy.generalInfo')}</Typography>

                            <Grid container justify="space-between">
                                <Grid item xs={12} md={6}>
                                    <InputField
                                        className={classes.input}
                                        name="title"
                                        type="text"
                                        label={t('newVacancy.function')}
                                        initialValue={vacancy.title || ''}
                                        form={form}
                                    />
                                    {paginatedVacancyTypes.types && (
                                        <SelectField
                                            label={t('newVacancy.vacancyType')}
                                            placeholder="Selecteer"
                                            name="type"
                                            options={mapToSelect(paginatedVacancyTypes.types, 'description', 'id')}
                                            initialValue={vacancy.type ? vacancy.type.id || '' : ''}
                                            loading={loadingVacancy}
                                            helper={false}
                                            form={form}
                                        />
                                    )}
                                    <Grid container justify="space-between">
                                        <Grid item xs={12} md={6}>
                                            {educationGrades.educationGrades && (
                                                <SelectField
                                                    label={t('newVacancy.eductionGrade')}
                                                    placeholder="Selecteer"
                                                    name="educationGrade"
                                                    options={mapToSelect(
                                                        educationGrades.educationGrades,
                                                        'description',
                                                        'id'
                                                    )}
                                                    initialValue={
                                                        vacancy.educationGrade ? vacancy.educationGrade.id || '' : ''
                                                    }
                                                    loading={loadingVacancy}
                                                    helper={false}
                                                    form={form}
                                                    error={false}
                                                    errorMessage={t('newVacancy.errorEducation')}
                                                />
                                            )}
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            {salaryScales && salaryScales.types && (
                                                <SelectField
                                                    label={t('profileForm.functionScale')}
                                                    placeholder={t('newVacancy.selectPlaceholder')}
                                                    options={mapToSelect(salaryScales.types, 'description', 'id')}
                                                    name="salaryScale"
                                                    initialValue={
                                                        vacancy.salaryScale ? vacancy.salaryScale.id || '' : ''
                                                    }
                                                    loading={loadingVacancy}
                                                    form={form}
                                                />
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {cities && (
                                        <AutoCompleteInput
                                            label={t('newVacancy.place')}
                                            placeholder={t('newVacancy.place')}
                                            noOptionsText={t('newVacancy.noOptionsPlace')}
                                            className={classes.input}
                                            name="city"
                                            initialValue={vacancy.city}
                                            form={form}
                                            data={{
                                                query: GET_CITIES,
                                                response: 'cities'
                                            }}
                                        />
                                    )}
                                    {contactPersons && (
                                        <SelectField
                                            label={t('newVacancy.contactPerson')}
                                            placeholder={t('newVacancy.contactPerson')}
                                            helper={false}
                                            options={mapToSelect(contactPersons, 'email', 'id')}
                                            name="contactPerson"
                                            initialValue={vacancy.contactPerson ? vacancy.contactPerson.id : ''}
                                            loading={loadingVacancy}
                                            form={form}
                                        />
                                    )}
                                    <Grid container justify="space-between">
                                        <Grid item xs={12} md={6}>
                                            {durations.durations && (
                                                <SelectField
                                                    label={t('newVacancy.duration')}
                                                    placeholder="Duur"
                                                    helper={false}
                                                    options={mapToSelect(durations.durations, 'description', 'id')}
                                                    name="duration"
                                                    form={form}
                                                    initialValue={vacancy.duration ? vacancy.duration.id || '' : ''}
                                                    loading={loadingVacancy}
                                                />
                                            )}
                                        </Grid>
                                        {workingHoursList.workingHoursList && (
                                            <Grid item xs={12} md={6}>
                                                <SelectField
                                                    label={t('newVacancy.workingHoursAWeekLabel')}
                                                    placeholder={t('newVacancy.hours')}
                                                    helper={false}
                                                    options={mapToSelect(
                                                        workingHoursList.workingHoursList,
                                                        'value',
                                                        'id'
                                                    )}
                                                    initialValue={
                                                        vacancy.workingHoursAWeek
                                                            ? vacancy.workingHoursAWeek.id || ''
                                                            : ''
                                                    }
                                                    loading={loadingVacancy}
                                                    name="workingHoursAWeek"
                                                    form={form}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <Typography className={classes.heading}>{t('newVacancy.publication')}</Typography>
                            <Grid container justify="space-between">
                                {draft.publicationPeriod &&
                                    draft.publicationPeriod.map((item, idx) => (
                                        <PublicationBlock
                                            key={idx}
                                            form={form}
                                            item={item}
                                            idx={idx}
                                            vacancyVisibilities={vacancyVisibilities}
                                            onRemove={removePublication}
                                            onChange={handlePeriodChange}
                                        />
                                    ))}

                                <Grid item xs={12} md={6}>
                                    <Grid container justify="space-between">
                                        <Grid container justify="space-between">
                                            <Grid container>
                                                <Typography onClick={addPublication} className={classes.addBar}>
                                                    <Add /> {t('newVacancy.addNewDates')}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {showVisibleInApiOptions && <Grid item xs={12}>
                                <Typography className={classes.label}>
                                    {t('newVacancy.visibleForThirdParties')}
                                </Typography>
                                <Checkbox
                                    label={t('newVacancy.visibleInApi')}
                                    name={'visibleInApi'}
                                    value={vacancy.visibleInApi}
                                    checked={vacancy.apiKeys && vacancy.apiKeys.length !== 0}
                                    form={form}
                                />
                            </Grid>}
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <Typography className={classes.heading}>{t('newVacancy.expertiseTitle')}</Typography>
                            <Typography variant="subtitle1" className={classes.label}>
                                {t('newVacancy.expertiseLabel')}
                            </Typography>
                            <Box mt={2} />
                            <div className={classes.customGrid}>
                                <CheckboxGroup
                                    name="expertiseList"
                                    formClasses={classes.customGrid}
                                    itemRootClasses={classes.gridItem}
                                    options={
                                        expertises.expertises
                                            ? expertises.expertises.map((item) => {
                                                  return {
                                                      value: item.value,
                                                      label: item.label
                                                  };
                                              })
                                            : []
                                    }
                                    initialValue={vacancy.expertiseList}
                                    form={form}
                                />
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <Typography className={classes.heading}>{t('newVacancy.changeInfoTitle')}</Typography>

                            <Grid container justify="space-between">
                                <Grid item xs={12} md={8}>
                                    <FileUpload
                                        name="imageOrVideo"
                                        accept="video/*,image/*"
                                        acceptLabel="Afbeelding"
                                        error={false}
                                        maxFileSize={false}
                                        label={t('newVacancy.chooseImage')}
                                        initialValue={
                                            draft.imageOrVideo && vacancy.imageOrVideo
                                                ? {
                                                      ...vacancy.imageOrVideo,
                                                      type: vacancy.imageOrVideo.contentType
                                                  }
                                                : null
                                        }
                                        onSucces={handleFile('imageOrVideo')}
                                        onClear={clearFile('imageOrVideo')}
                                    />
                                    <InputField
                                        className={classes.input}
                                        name="videoURL"
                                        type="text"
                                        label={t('newVacancy.videoUrl')}
                                        initialValue={vacancy.videoURL || ''}
                                        form={form}
                                    />
                                    <RichInputField
                                        classes={{ editor: classes.richTextField }}
                                        name="description"
                                        title={t('newVacancy.vacancyDescription')}
                                        initialValue={vacancy.description || ''}
                                        error={false}
                                        form={form}
                                    />
                                    <Typography className={classes.label}>{t('newVacancy.uploadFile')}</Typography>
                                    <FileUpload
                                        name="attachments"
                                        error={false}
                                        label={t('newVacancy.chooseFile')}
                                        initialValue={vacancy.attachments}
                                        onSucces={handleFiles('attachments')}
                                        onClear={clearFiles('attachments')}
                                        noFormChance={true}
                                        multi={true}
                                    />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
                <Dialog
                    open={confirmOpen}
                    onClose={handleConfirmClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{t('vacancyDetail.deleteTitle')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t('vacancyDetail.deleteText')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmClose} color="primary" label={t('vacancyDetail.cancleButton')} />
                        <Button
                            onClick={deleteVacancy}
                            color="primary"
                            variant="contained"
                            autoFocus
                            label={t('vacancyDetail.deleteButton')}
                        />
                    </DialogActions>
                </Dialog>
            </div>
        </React.Fragment>
    );
};

export default NewVacancy;
