import gql from 'graphql-tag';

export const GQL_CITIES = gql`
    query($filter: CityFilterInput!) {
        cities(pagination: { page: 0, pageSize: 50 }, filter: $filter) {
            cities {
               id
               name
            }
        }
    }
`