import React, { useState, useMemo, useEffect } from 'react';
import { GQL_CITIES, GQL_CITY } from './graphql';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Button, InputField, SelectField, AutoCompleteInput } from 'shared';
import SearchIcon from '@material-ui/icons/Search';
import { SEARCH_TRIGGERS } from './queries';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto'
    },
    compactRoot: {
        flexWrap: 'nowrap'
    },
    loading: {
        pointerEvens: 'none',
        opacity: '.5'
    },
    barWrapper: {
        background: theme.palette.background.secondary,
        borderRadius: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(3.375),

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3.375, 1),
            flexFlow: 'column nowrap',
            borderRadius: '25px'
        }
    },
    searchBarFields: {
        display: 'inherit',
        flexFlow: 'inherit',
        flex: '1 0 auto',
        transition: 'height 400ms ease'
    },
    searchBarFieldsMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    compact: {
        borderRadius: 0,
        padding: `${theme.spacing(3.375)}px 0`
    },
    textField: {},
    input: {
        background: theme.palette.background.default,
        border: 'none',
        borderRadius: '35px',
        flex: '2',
        // height: '100%',
        margin: `0 ${theme.spacing(1)}px`,
        boxSizing: 'border-box',
        '& .MuiInputBase-input': {
            border: 'none',
            borderRadius: '35px',
            padding: `${theme.spacing(2.625)}px ${theme.spacing(2.75)}px`
        },
        '& .MuiInputBase-input:focus': {
            border: 'none',
            boxShadow: '0 0 0 4px ' + theme.palette.background.dark
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1.5)
        }
    },
    button: {
        flex: 'inherit',
        marginBottom: 0,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: 0,
        maxHeight: '61px'
    },
    filterButton: {
        marginTop: theme.spacing(1)
    },
    '.MuiFormControl-root': {
        heigth: '100%'
    },
    title: {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3)
        }
    },
    recent: {
        alignItems: 'center',
        display: 'flex',
        flexFlow: 'row wrap',
        padding: `0 ${theme.spacing(6)}px`,
        marginTop: theme.spacing(2.5),
        [theme.breakpoints.down('sm')]: {
            padding: 0
        }
    },
    item: {
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: '35px',
        color: theme.palette.primary.main,
        fontSize: '1em',
        marginLeft: theme.spacing(1.3),
        padding: `${theme.spacing(0.4)}px ${theme.spacing(1.8)}px`,
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            color: '#fff'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(0.5)
        }
    },
    label: {
        color: theme.palette.text.disabled,
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px'
        }
    },
    selectRoot: {
        '&&': { // By nesting this one more level, this rule is more specific than just the top-level class meaning it takes precedence over the class in the SelectField
            margin: 0,
            width: 'auto',
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(1.5)
            },
            '& .MuiInputBase-input': {
                paddingTop: theme.spacing(2.625),
                paddingBottom: theme.spacing(2.625)
            }
        }
    },
    select: {
        '&&': { // By nesting this one more level, this rule is more specific than just the top-level class meaning it takes precedence over the class in the SelectField
            flex: '1',
            background: theme.palette.background.default,
            border: 'none',
            borderRadius: '35px',
            height: '100%',
            margin: `0 ${theme.spacing(1)}px`,
            width: '160px',

            [theme.breakpoints.down('sm')]: {
                width: 'auto'
            },

            '&:focus, &:active': {
                background: theme.palette.background.default,
                border: 'none',
                borderRadius: '35px',
                boxShadow: '0 0 0 4px ' + theme.palette.background.dark
            }
        }
    },
    autoCompleteRoot: {
        flex: 2,
        margin: theme.spacing(0, 1),
        '& $inputComponent': {
            flex: '2',
            height: '100%',
            margin: 0,
            padding: theme.spacing(0, 8, 0, 0),
            border: 0
        },
        '& $nativeInput': {
            border: 'none',
            borderRadius: '35px',
            padding: theme.spacing(2.625, 2.75)
        },
        '& $textField': {
            margin: 0,
            height: '100%',
            background: theme.palette.background.default,
            border: 'none',
            borderRadius: '35px',
            '&:focus-within': {
                border: 'none',
                boxShadow: '0 0 0 4px ' + theme.palette.background.dark
            }
        },
        '& $endAdornment': {
            right: '10px'
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1.5)
        }
    },
    inputComponent: {},
    nativeInput: {},
    endAdornment: {}
}));

const SearchBar = (props) => {
    const {
        onChange,
        currentValue,
        variant = 'DEFAULT',
        initialValue = false,
        loading = false,
        loggedIn,
        hasFilters = false,
        accordion = false,
        filterClick
    } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const [city, setCity] = useState(initialValue.city || '');
    const [distance, setDistance] = useState(initialValue.distance || '');
    const [search, setSearch] = useState(initialValue.position || '');
    const [searchState, setSearchState] = useState(false);
    const [width, setWidth] = React.useState(window.innerWidth);
    const [searchTriggers, setSearchTriggers] = useState(null);

    const [getSearchTriggers] = useLazyQuery(SEARCH_TRIGGERS, {
        onCompleted: (data) => setSearchTriggers(data.searchTriggers),
        onError: (error) => console.warn('searchTriggers error:', error)
    });
    const { data: initialCity = {}, loading: loadingCity } = useQuery(GQL_CITY, {
        variables: { id: initialValue && initialValue.city ? initialValue.city : -1 }
    });

    useEffect(() => {
        if (loggedIn) {
            getSearchTriggers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn]);

    useEffect(() => {
        window.addEventListener('resize', updateWidthAndHeight);
        return () => window.removeEventListener('resize', updateWidthAndHeight);
    });

    /// this will slow down the application need to fix
    useEffect(() => {
        if (currentValue) {
            currentValue({
                city: city,
                distance,
                search
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [city, distance, search]);

    useEffect(() => {
        if (!loading && !loadingCity) {
            if (initialCity && initialCity.city) {
                setCity(initialCity.city);
            }
            if (initialValue.distance) setDistance(initialValue.distance);
            if (initialValue.search) setSearch(initialValue.search);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, loadingCity]);

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
    };

    const options = useMemo(
        () => [
            {
                value: '25',
                label: t('searchBar.option25')
            },
            {
                value: '50',
                label: t('searchBar.option50')
            },
            {
                value: '100',
                label: t('searchBar.option100')
            },
            {
                value: '200',
                label: t('searchBar.option200')
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleSearch = () => {
        setSearchState(false);
        onChange({
            search,
            city,
            distance
        });
    };

    const handleRecentSearch = (item) => {
        const options = {};
        if (item.search) {
            options.search = item.search;
        }
        if (item.city) {
            options.city = item.city;
        }
        if (item.distance) {
            options.distance = item.distance;
        }
        onChange({ ...options });
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <Grid
            className={classNames(
                classes.root,
                { [classes.compactRoot]: variant === 'compact' },
                { [classes.loading]: loading }
            )}
            container
            direction="column"
        >
            {variant === 'DEFAULT' && (
                <Grid className={classes.title}>
                    <Typography variant="h5" className={classes.title}>
                        Snel kansen zoeken
                    </Typography>
                </Grid>
            )}
            <Grid>
                <div
                    className={classNames(classes.barWrapper, {
                        [classes.compact]: variant === 'compact'
                    })}
                >
                    <div className={classes.searchBarFields}>
                        <InputField
                            placeholder={t('searchBar.position')}
                            className={classes.input}
                            value={search}
                            onChange={(value) => setSearch(value)}
                            label={false}
                            onKeyPress={handleKeyPress}
                        />
                        <AutoCompleteInput
                            classes={{
                                root: classes.autoCompleteRoot,
                                inputComponent: classes.inputComponent,
                                textField: classes.textField,
                                nativeInput: classes.nativeInput,
                                endAdornment: classes.endAdornment
                            }}
                            placeholder={t('searchBar.city')}
                            label={false}
                            onChange={(ev, value) => setCity(value)}
                            initialValue={city}
                            noOptionsText={t('searchBar.searchCity')}
                            data={{
                                query: GQL_CITIES,
                                response: 'cities'
                            }}
                            onKeyPress={handleKeyPress}
                        />
                        <SelectField
                            value={distance}
                            className={classes.select}
                            rootClass={classes.selectRoot}
                            options={options}
                            option={distance}
                            initialValue={distance}
                            helper={false}
                            placeholder={t('searchBar.distance')}
                            onChange={(value) => setDistance(value)}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        label={width < 1366 ? t('searchBar.search') : t('searchBar.button')}
                        onClick={() =>
                            width < 980 && !searchState && accordion ? setSearchState(true) : handleSearch()
                        }
                        iconRight={() => <SearchIcon color="secondary" />}
                        onKeyPress={handleKeyPress}
                    />
                    {width < 960 && hasFilters && (
                        <Button
                            className={[classes.button, classes.filterButton]}
                            variant="contained"
                            color="primary"
                            label="Filteren"
                            onClick={filterClick}
                        />
                    )}
                </div>
            </Grid>
            {variant === 'DEFAULT' && loggedIn && (
                <Grid className={classes.recent}>
                    <Typography className={classes.label}>Opgeslagen zoekopdrachten:</Typography>
                    {searchTriggers &&
                        searchTriggers.forEach((item) => {
                            if (searchTriggers.indexOf(item) >= item) {
                                return (
                                    <Typography
                                        key={item.id}
                                        onClick={() => handleRecentSearch(item)}
                                        className={classes.item}
                                    >
                                        {item.search}
                                    </Typography>
                                );
                            }
                        })}
                </Grid>
            )}
        </Grid>
    );
};

export default SearchBar;
