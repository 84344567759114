import React from 'react';
import CustomIcon from './CustomIcon'

const Upload = props => (
    <CustomIcon {...props} svgAtts={{
        stroke: 'currentColor',
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        strokeWidth: '2'
    }}>
        <path fill="none" d="M3 17v3a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-3"/>
        <polyline fill="none" points="16 6 12 2 8 6"/> 
        <line x1="12" x2="12" y1="2" y2="16"/>
    </CustomIcon>
);

export default Upload;
