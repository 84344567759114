import React from 'react';
import classNames from 'classnames';
import { Button as MButton, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        borderRadius: '35px',
        padding: `${theme.spacing(1.5)}px ${theme.spacing(3)}px`,
        '&.MuiButton-root': {
            boxShadow: 'none',
            transition: 'color 300ms, background 300ms',
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    color: theme.palette.primary.contrastText,
                    background: theme.palette.primary.lightBackground,

                    '& .MuiSvgIcon-root': {
                        color: theme.palette.primary.contrastText,
                        transition: 'color 300ms',
                    }
                }
            }
        },
        '&.MuiButton-containedSecondary': {
            '& .MuiSvgIcon-root': {
                color: theme.palette.secondary.contrastText,
            }
        },
        '&.MuiButton-outlined': {
            boxShadow: 'none',
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.light,
            transition: 'color 300ms, background 300ms, borderColor 300ms',

            '& .MuiSvgIcon-root': {
                color: theme.palette.primary.main,
            },
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    color: theme.palette.primary.contrastText,
                    background: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,

                    '& .MuiSvgIcon-root': {
                        color: theme.palette.primary.contrastText,
                    }
                }
            }
        },
        '&.MuiButton-outlined.MuiButton-outlinedPrimary': {
            boxShadow: 'none',
            color: theme.palette.text.secondary,

            '& .MuiSvgIcon-root': {
                color: theme.palette.text.secondary,
            },
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    color: theme.palette.primary.contrastText,
                    background: theme.palette.text.secondary,
                    borderColor: theme.palette.text.secondary,
                    '& .MuiSvgIcon-root': {
                        color: theme.palette.primary.contrastText,
                    }
                }
            }
        }
    },
    yellow: {
        '&.MuiButton-root': {
            background: theme.palette.background.yellow,

            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    color: theme.palette.text.secondary,
                    background: theme.palette.background.yellowContrast,
                    textDecoration: 'none',
                    '& .MuiSvgIcon-root': {
                        color: theme.palette.text.secondary,
                    }
                }
            }
        }
    },
    blue: {
        '&.MuiButton-root': {
            background: theme.palette.background.secondary,

            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    color: theme.palette.text.secondary,
                    background: theme.palette.background.secondaryContrast,
                    textDecoration: 'none',
                    '& .MuiSvgIcon-root': {
                        color: theme.palette.text.secondary,
                    }
                }
            }
        }
    },
    pink: {
        '&.MuiButton-root': {
            background: theme.palette.background.pink,
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    color: theme.palette.primary.contrastText,
                    background: theme.palette.background.pinkContrast,
                    textDecoration: 'none',
                    '& .MuiSvgIcon-root': {
                        color: theme.palette.primary.contrastText,
                    }
                }
            }
        }
    },
    purple: {
        '&.MuiButton-root': {
            background: theme.palette.primary.light,
            [theme.breakpoints.up('md')]: {
                '&:hover': {
                    color: theme.palette.primary.contrastText,
                    background: theme.palette.primary.main,
                    textDecoration: 'none',
                    '& .MuiSvgIcon-root': {
                        color: theme.palette.primary.contrastText,
                    }
                }
            }
        }
    },
    fullWidth: {
        width: '100%'
    },
    label: {
        margin: props => props.iconRight ? '0 10px 0 0' : (props.loading ? '0 10px 0 0' : '0'),
        [theme.breakpoints.down(580)]: {
            fontSize: '1rem'
        }
    }
}));

// purple pink blue yellow
const Button = props => {
    const {
        label,
        variant,
        color,
        className,
        onClick,
        to,
        component,
        iconLeft,
        iconRight,
        fullWidth = false,
        customColor,
        loading = false,
        ...otherProps
    } = props;
    const classes = useStyles(props);

    return (
        <MButton
            className={classNames(classes.root, classes[variant], classes[customColor], className, { [classes.fullWidth]: fullWidth })}
            onClick={onClick}
            color={color}
            variant={variant}
            component={component}
            to={to}
            {...otherProps}
        >
            {iconLeft && iconLeft()}
            {label && <span className={classes.label}>{label}</span>}
            {iconRight && iconRight()}
            {loading && <CircularProgress size={24} />}
        </MButton>
    );
};

export default Button;
