import { GET_USER } from 'api';
import { client } from 'api';

export const loginUser = value => dispatch => {
    dispatch({
        type: LOGIN,
        payload: value
    });
};

export const logoutUser = value => dispatch => {
    dispatch({
        type: LOGOUT,
        payload: value
    });
};

export const setUser = value => dispatch => {
    dispatch({
        type: SET_USER,
        payload: value
    });
};

export const setToken = value => dispatch => {
    dispatch({
        type: SET_TOKEN,
        payload: value
    });
};

export const setUserRoles = roles => dispatch => {
    dispatch({
        type: SET_ROLES,
        payload: roles
    });
};
export const setUserRole = role => dispatch => {
    dispatch({
        type: SET_ROLE,
        payload: role
    });
};

export const setUserId = id => dispatch => {
    dispatch({
        type: SET_USER_ID,
        payload: id
    });
};

export const setUserData = id => dispatch => {
    if (!id) return;
    client
        .query({
            query: GET_USER,
            variables: {
                id: id
            }
        })
        .then(result => {
            const userData = result.data.user;
            if (userData) {
                dispatch(setUser(userData));
                dispatch(setUserId(id));
            }
        });
};

export const LOGIN = 'LOGIN';
export const SET_USER = 'SET_USER';
export const SET_TOKEN = 'SET_TOKEN';
export const LOGOUT = 'LOGOUT';
export const SET_ROLES = 'SET_ROLES';
export const SET_ROLE = 'SET_ROLE';
export const SET_USER_ID = 'SET_USER_ID';
